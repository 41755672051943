import gql from "graphql-tag"
import * as Urql from "urql"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string
	String: string
	Boolean: boolean
	Int: number
	Float: number
	DateTime: any
}

export enum AdmissionTypes {
	NonResidentIndian = "NonResidentIndian",
	ResidentIndian = "ResidentIndian",
	Staff = "Staff",
}

export type AttendanceDataResponse = {
	__typename?: "AttendanceDataResponse"
	count: Scalars["Float"]
	label: Scalars["String"]
}

export type AttendanceStatsResponse = {
	__typename?: "AttendanceStatsResponse"
	absent: Array<ExtendedStudent>
	present: Array<ExtendedStudentAttendance>
	total: Scalars["Float"]
}

export type AttendanceTiming = {
	__typename?: "AttendanceTiming"
	inAt: Scalars["String"]
	inStartBeforeMinutes: Scalars["Float"]
	notifyTeacherAfterMinutes: Scalars["Float"]
	outAt: Scalars["String"]
	outStartBeforeMinutes: Scalars["Float"]
}

export type AttendanceTimingInput = {
	inAt: Scalars["String"]
	inStartBeforeMinutes: Scalars["Float"]
	notifyTeacherAfterMinutes: Scalars["Float"]
	outAt: Scalars["String"]
	outStartBeforeMinutes: Scalars["Float"]
}

export type AuthResponse = {
	__typename?: "AuthResponse"
	errors?: Maybe<Array<FieldError>>
	school?: Maybe<School>
	sessionId?: Maybe<Scalars["String"]>
}

export type Author = {
	__typename?: "Author"
	authorType: AuthorTypes
	schoolId?: Maybe<Scalars["String"]>
	staffId?: Maybe<Scalars["String"]>
	studentId?: Maybe<Scalars["String"]>
}

export enum AuthorTypes {
	School = "School",
	Staff = "Staff",
	Student = "Student",
}

export enum BloodGroups {
	AbNeg = "AB_NEG",
	AbPos = "AB_POS",
	ANeg = "A_NEG",
	APos = "A_POS",
	BNeg = "B_NEG",
	BPos = "B_POS",
	ONeg = "O_NEG",
	OPos = "O_POS",
}

export type BusRoute = {
	__typename?: "BusRoute"
	_id: Scalars["String"]
	busConductorName: Scalars["String"]
	busConductorPhone?: Maybe<Scalars["String"]>
	busDriverName: Scalars["String"]
	busDriverPhone?: Maybe<Scalars["String"]>
	createdAt: Scalars["DateTime"]
	routeName: Scalars["String"]
	updatedAt: Scalars["DateTime"]
	vehicleNo?: Maybe<Scalars["String"]>
}

export type BusRouteInput = {
	busConductorName: Scalars["String"]
	busConductorPhone?: InputMaybe<Scalars["String"]>
	busDriverName: Scalars["String"]
	busDriverPhone?: InputMaybe<Scalars["String"]>
	routeName: Scalars["String"]
	vehicleNo?: InputMaybe<Scalars["String"]>
}

export type BusRouteWithFieldErrorsResponse = {
	__typename?: "BusRouteWithFieldErrorsResponse"
	busRoute?: Maybe<BusRoute>
	errors?: Maybe<Array<FieldError>>
}

export type Circular = {
	__typename?: "Circular"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	message: Scalars["String"]
	targets: Array<UserTargets>
	title: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type CircularInput = {
	isActive?: InputMaybe<Scalars["Boolean"]>
	message: Scalars["String"]
	targets: Array<UserTargets>
	title: Scalars["String"]
}

export type CircularWithFieldErrorsResponse = {
	__typename?: "CircularWithFieldErrorsResponse"
	circular?: Maybe<Circular>
	errors?: Maybe<Array<FieldError>>
}

export type Class = {
	__typename?: "Class"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type ClassInput = {
	name: Scalars["String"]
}

export type ClassSection = {
	__typename?: "ClassSection"
	_id: Scalars["String"]
	attendanceTiming: AttendanceTiming
	classId: Scalars["String"]
	classTeacherId?: Maybe<Scalars["String"]>
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	updatedAt: Scalars["DateTime"]
	workingWeekdays?: Maybe<Array<Scalars["String"]>>
}

export type ClassSectionInput = {
	attendanceTiming: AttendanceTimingInput
	classId: Scalars["String"]
	name: Scalars["String"]
}

export type ClassSectionWithFieldErrorsResponse = {
	__typename?: "ClassSectionWithFieldErrorsResponse"
	classSection?: Maybe<ExtendedClassSection>
	errors?: Maybe<Array<FieldError>>
}

export type ClassWithFieldErrorsResponse = {
	__typename?: "ClassWithFieldErrorsResponse"
	class?: Maybe<ExtendedClass>
	errors?: Maybe<Array<FieldError>>
}

export type Department = {
	__typename?: "Department"
	_id: Scalars["String"]
	attendanceTiming: StaffAttendanceTiming
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	pointsBalance?: Maybe<Scalars["Float"]>
	type: DepartmentTypes
	updatedAt: Scalars["DateTime"]
	workingWeekdays?: Maybe<Array<Scalars["String"]>>
}

export type DepartmentInput = {
	attendanceTiming: StaffAttendanceTimingInput
	name: Scalars["String"]
	type: DepartmentTypes
}

export enum DepartmentTypes {
	NonTeaching = "NonTeaching",
	Teaching = "Teaching",
}

export type DepartmentWithFieldErrorsResponse = {
	__typename?: "DepartmentWithFieldErrorsResponse"
	department?: Maybe<Department>
	errors?: Maybe<Array<FieldError>>
}

export type EditGalleryInput = {
	description?: InputMaybe<Scalars["String"]>
	title?: InputMaybe<Scalars["String"]>
}

export type EditPenaltyCategoryInput = {
	description: Scalars["String"]
	name: Scalars["String"]
}

export type EditPenaltyItemInput = {
	description: Scalars["String"]
	name: Scalars["String"]
	points: Scalars["Float"]
}

export type EditPointsRedeemItemInput = {
	description: Scalars["String"]
	name: Scalars["String"]
	points: Scalars["Float"]
}

export type EditResourceInput = {
	classId?: InputMaybe<Scalars["String"]>
	classSectionId?: InputMaybe<Scalars["String"]>
	description?: InputMaybe<Scalars["String"]>
	name?: InputMaybe<Scalars["String"]>
	subTopic?: InputMaybe<Scalars["String"]>
	subjectId?: InputMaybe<Scalars["String"]>
	targets: Array<UserTargets>
	topicId?: InputMaybe<Scalars["String"]>
}

export type EditRewardCategoryInput = {
	description: Scalars["String"]
	name: Scalars["String"]
}

export type EditRewardItemInput = {
	description: Scalars["String"]
	name: Scalars["String"]
	points: Scalars["Float"]
}

export type EditShowcaseInput = {
	description: Scalars["String"]
	title: Scalars["String"]
}

export type EditStaffInput = {
	address?: InputMaybe<Scalars["String"]>
	bloodGroup?: InputMaybe<BloodGroups>
	dateOfBirth?: InputMaybe<Scalars["DateTime"]>
	designation?: InputMaybe<Scalars["String"]>
	email?: InputMaybe<Scalars["String"]>
	gender?: InputMaybe<Genders>
	name?: InputMaybe<Scalars["String"]>
	phone?: InputMaybe<Scalars["String"]>
	subjectIds?: InputMaybe<Array<Scalars["String"]>>
}

export type EditStudentClassInput = {
	classId: Scalars["String"]
	classSectionId: Scalars["String"]
}

export type EditStudentInput = {
	address?: InputMaybe<Scalars["String"]>
	admissionDate?: InputMaybe<Scalars["DateTime"]>
	admissionType?: InputMaybe<AdmissionTypes>
	bloodGroup?: InputMaybe<BloodGroups>
	busRouteId?: InputMaybe<Scalars["String"]>
	dateOfBirth?: InputMaybe<Scalars["DateTime"]>
	email?: InputMaybe<Scalars["String"]>
	father?: InputMaybe<ParentInput>
	gender?: InputMaybe<Genders>
	guardian1?: InputMaybe<ParentInput>
	guardian2?: InputMaybe<ParentInput>
	houseId?: InputMaybe<Scalars["String"]>
	isALS?: InputMaybe<Scalars["Boolean"]>
	isELS?: InputMaybe<Scalars["Boolean"]>
	mother?: InputMaybe<ParentInput>
	name?: InputMaybe<Scalars["String"]>
	nationality?: InputMaybe<Scalars["String"]>
	phone?: InputMaybe<Scalars["String"]>
	transportMode?: InputMaybe<Scalars["String"]>
}

export type EmbeddedPdf = {
	__typename?: "EmbeddedPDF"
	createdAt: Scalars["DateTime"]
	key: Scalars["String"]
	updatedAt: Scalars["DateTime"]
	url: Scalars["String"]
}

export type EmbeddedPicture = {
	__typename?: "EmbeddedPicture"
	createdAt: Scalars["DateTime"]
	key: Scalars["String"]
	updatedAt: Scalars["DateTime"]
	url: Scalars["String"]
	variants?: Maybe<PictureVariants>
}

export type EmbeddedVideo = {
	__typename?: "EmbeddedVideo"
	createdAt: Scalars["DateTime"]
	key: Scalars["String"]
	thumbnail?: Maybe<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
	url: Scalars["String"]
	variants?: Maybe<VideoVariants>
}

export type Entry = {
	readerHexId: Scalars["String"]
	tagHexId: Scalars["String"]
}

export type ExtendedAuthor = {
	__typename?: "ExtendedAuthor"
	authorType: AuthorTypes
	school?: Maybe<School>
	schoolId?: Maybe<Scalars["String"]>
	staff?: Maybe<Staff>
	staffId?: Maybe<Scalars["String"]>
	student?: Maybe<Student>
	studentId?: Maybe<Scalars["String"]>
}

export type ExtendedClass = {
	__typename?: "ExtendedClass"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	sections: Array<ExtendedClassSection>
	updatedAt: Scalars["DateTime"]
}

export type ExtendedClassSection = {
	__typename?: "ExtendedClassSection"
	_id: Scalars["String"]
	attendanceTiming: AttendanceTiming
	classId: Scalars["String"]
	classTeacher?: Maybe<Staff>
	classTeacherId?: Maybe<Scalars["String"]>
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	updatedAt: Scalars["DateTime"]
	workingWeekdays?: Maybe<Array<Scalars["String"]>>
}

export type ExtendedFeedPost = {
	__typename?: "ExtendedFeedPost"
	_id: Scalars["String"]
	attachments?: Maybe<MediaAttachments>
	author: ExtendedAuthor
	createdAt: Scalars["DateTime"]
	isApproved?: Maybe<Scalars["Boolean"]>
	isLiked?: Maybe<Scalars["Boolean"]>
	likes?: Maybe<Array<FeedPostLike>>
	links?: Maybe<Links>
	text?: Maybe<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
}

export type ExtendedHouse = {
	__typename?: "ExtendedHouse"
	_id: Scalars["String"]
	captain?: Maybe<Student>
	captainId?: Maybe<Scalars["String"]>
	colorHex: Scalars["String"]
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	pointsBalance?: Maybe<Scalars["Float"]>
	updatedAt: Scalars["DateTime"]
}

export type ExtendedPenalty = {
	__typename?: "ExtendedPenalty"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	from: ExtendedAuthor
	penaltyItemIds: Array<Scalars["String"]>
	penaltyItems: Array<ExtendedPenaltyItem>
	to: Array<ExtendedAuthor>
	totalPoints: Scalars["Float"]
	updatedAt: Scalars["DateTime"]
}

export type ExtendedPenaltyCategory = {
	__typename?: "ExtendedPenaltyCategory"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	items?: Maybe<Array<PenaltyItem>>
	name: Scalars["String"]
	target: UserTargets
	updatedAt: Scalars["DateTime"]
}

export type ExtendedPenaltyItem = {
	__typename?: "ExtendedPenaltyItem"
	_id: Scalars["String"]
	category: PenaltyCategory
	categoryId: Scalars["String"]
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	name: Scalars["String"]
	points: Scalars["Float"]
	updatedAt: Scalars["DateTime"]
}

export type ExtendedPointsRedeemTransaction = {
	__typename?: "ExtendedPointsRedeemTransaction"
	_id: Scalars["String"]
	approvedAt?: Maybe<Scalars["DateTime"]>
	arePointsRefunded?: Maybe<Scalars["Boolean"]>
	author: ExtendedAuthor
	createdAt: Scalars["DateTime"]
	isApproved?: Maybe<Scalars["Boolean"]>
	isRejected?: Maybe<Scalars["Boolean"]>
	pointsRedeemItem: PointsRedeemItem
	pointsRedeemItemId: Scalars["String"]
	rejectedAt?: Maybe<Scalars["DateTime"]>
	remarks?: Maybe<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
}

export type ExtendedResource = {
	__typename?: "ExtendedResource"
	_id: Scalars["String"]
	attachments: MediaAttachments
	author: ExtendedAuthor
	class: Class
	classId: Scalars["String"]
	classSection?: Maybe<ClassSection>
	classSectionId?: Maybe<Scalars["String"]>
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	name: Scalars["String"]
	subTopic?: Maybe<Scalars["String"]>
	subject: Subject
	subjectId: Scalars["String"]
	targets: Array<UserTargets>
	topic: Topic
	topicId: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type ExtendedRestrictedEntryRecord = {
	__typename?: "ExtendedRestrictedEntryRecord"
	_id: Scalars["String"]
	author: ExtendedAuthor
	createdAt: Scalars["DateTime"]
	inAt: Scalars["DateTime"]
	isFinal: Scalars["Boolean"]
	outAt: Scalars["DateTime"]
	readerHexId: Scalars["String"]
	restrictedZone: RestrictedZone
	restrictedZoneId: Scalars["String"]
	tagHexId: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type ExtendedRestrictedZone = {
	__typename?: "ExtendedRestrictedZone"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	readerIds: Array<Scalars["String"]>
	readers: Array<Reader>
	unauthorizedClasses: Array<UnauthorizedClass>
	unauthorizedDepartmentIds: Array<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
}

export type ExtendedReward = {
	__typename?: "ExtendedReward"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	from: ExtendedAuthor
	rewardItemIds: Array<Scalars["String"]>
	rewardItems: Array<ExtendedRewardItem>
	to: Array<ExtendedAuthor>
	totalPoints: Scalars["Float"]
	updatedAt: Scalars["DateTime"]
}

export type ExtendedRewardCategory = {
	__typename?: "ExtendedRewardCategory"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	items?: Maybe<Array<RewardItem>>
	name: Scalars["String"]
	target: UserTargets
	updatedAt: Scalars["DateTime"]
}

export type ExtendedRewardItem = {
	__typename?: "ExtendedRewardItem"
	_id: Scalars["String"]
	category: RewardCategory
	categoryId: Scalars["String"]
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	name: Scalars["String"]
	points: Scalars["Float"]
	updatedAt: Scalars["DateTime"]
}

export type ExtendedShowcase = {
	__typename?: "ExtendedShowcase"
	_id: Scalars["String"]
	attachments?: Maybe<MediaAttachments>
	author: ExtendedAuthor
	authors?: Maybe<Array<ShowcaseAuthor>>
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	qrCode?: Maybe<EmbeddedPicture>
	title: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type ExtendedStaff = {
	__typename?: "ExtendedStaff"
	_id: Scalars["String"]
	address?: Maybe<Scalars["String"]>
	bloodGroup?: Maybe<BloodGroups>
	createdAt: Scalars["DateTime"]
	dateOfBirth?: Maybe<Scalars["DateTime"]>
	department: Department
	departmentId: Scalars["String"]
	designation: Scalars["String"]
	email?: Maybe<Scalars["String"]>
	gender?: Maybe<Genders>
	isWhitelisted?: Maybe<Scalars["Boolean"]>
	name: Scalars["String"]
	pastRoles?: Maybe<Array<ExtendedStaffRole>>
	phone?: Maybe<Scalars["String"]>
	picture?: Maybe<EmbeddedPicture>
	pointsBalance?: Maybe<Scalars["Float"]>
	roles?: Maybe<Array<ExtendedStaffRole>>
	staffId: Scalars["String"]
	subjectIds?: Maybe<Array<Scalars["String"]>>
	subjects: Array<Subject>
	tag?: Maybe<Tag>
	tagId?: Maybe<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
	username: Scalars["String"]
}

export type ExtendedStaffAttendance = {
	__typename?: "ExtendedStaffAttendance"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	inAt: Scalars["DateTime"]
	inReader?: Maybe<Reader>
	inReaderId?: Maybe<Scalars["String"]>
	isAbsent?: Maybe<Scalars["Boolean"]>
	isFinal?: Maybe<Scalars["Boolean"]>
	isInManual?: Maybe<Scalars["Boolean"]>
	isInRecorded?: Maybe<Scalars["Boolean"]>
	isLate?: Maybe<Scalars["Boolean"]>
	isOutManual?: Maybe<Scalars["Boolean"]>
	isOutRecorded?: Maybe<Scalars["Boolean"]>
	lateByMinutes?: Maybe<Scalars["Float"]>
	outAt: Scalars["DateTime"]
	outReader?: Maybe<Reader>
	outReaderId?: Maybe<Scalars["String"]>
	rssi: Scalars["Float"]
	staff: Staff
	staffId: Scalars["String"]
	temperature: Scalars["Float"]
	timing: StaffAttendanceTiming
	updatedAt: Scalars["DateTime"]
}

export type ExtendedStaffRole = {
	__typename?: "ExtendedStaffRole"
	assignedAt: Scalars["DateTime"]
	class?: Maybe<Class>
	classId?: Maybe<Scalars["String"]>
	classSection?: Maybe<ClassSection>
	classSectionId?: Maybe<Scalars["String"]>
	removedAt?: Maybe<Scalars["DateTime"]>
	role: StaffRoles
}

export type ExtendedStudent = {
	__typename?: "ExtendedStudent"
	_id: Scalars["String"]
	address?: Maybe<Scalars["String"]>
	admissionDate?: Maybe<Scalars["DateTime"]>
	admissionId: Scalars["String"]
	admissionType?: Maybe<AdmissionTypes>
	bloodGroup?: Maybe<BloodGroups>
	busRoute?: Maybe<BusRoute>
	busRouteId?: Maybe<Scalars["String"]>
	class: Class
	classId: Scalars["String"]
	classSection: ClassSection
	classSectionId: Scalars["String"]
	createdAt: Scalars["DateTime"]
	dateOfBirth?: Maybe<Scalars["DateTime"]>
	email?: Maybe<Scalars["String"]>
	father?: Maybe<Parent>
	gender?: Maybe<Genders>
	guardian1?: Maybe<Parent>
	guardian2?: Maybe<Parent>
	house?: Maybe<House>
	houseId?: Maybe<Scalars["String"]>
	isALS?: Maybe<Scalars["Boolean"]>
	isELS?: Maybe<Scalars["Boolean"]>
	isWhitelisted?: Maybe<Scalars["Boolean"]>
	mother?: Maybe<Parent>
	name: Scalars["String"]
	nationality?: Maybe<Scalars["String"]>
	phone: Scalars["String"]
	picture?: Maybe<EmbeddedPicture>
	pointsBalance?: Maybe<Scalars["Float"]>
	status?: Maybe<Scalars["String"]>
	tag?: Maybe<Tag>
	tagId?: Maybe<Scalars["String"]>
	transportMode?: Maybe<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
	username: Scalars["String"]
}

export type ExtendedStudentAttendance = {
	__typename?: "ExtendedStudentAttendance"
	_id: Scalars["String"]
	attendanceMarkedBy?: Maybe<ExtendedAuthor>
	class: Class
	classId: Scalars["String"]
	classSection: ClassSection
	classSectionId: Scalars["String"]
	createdAt: Scalars["DateTime"]
	inAt: Scalars["DateTime"]
	inReader?: Maybe<Reader>
	inReaderId?: Maybe<Scalars["String"]>
	isAbsent?: Maybe<Scalars["Boolean"]>
	isAttendanceMarkedByClassTeacher?: Maybe<Scalars["Boolean"]>
	isFinal?: Maybe<Scalars["Boolean"]>
	isInManual?: Maybe<Scalars["Boolean"]>
	isInRecorded?: Maybe<Scalars["Boolean"]>
	isLate?: Maybe<Scalars["Boolean"]>
	isOutManual?: Maybe<Scalars["Boolean"]>
	isOutRecorded?: Maybe<Scalars["Boolean"]>
	lateByMinutes?: Maybe<Scalars["Float"]>
	outAt: Scalars["DateTime"]
	outReader?: Maybe<Reader>
	outReaderId?: Maybe<Scalars["String"]>
	rssi: Scalars["Float"]
	student: ExtendedStudent
	studentId: Scalars["String"]
	temperature: Scalars["Float"]
	timing: AttendanceTiming
	updatedAt: Scalars["DateTime"]
}

export type ExtendedTag = {
	__typename?: "ExtendedTag"
	_id: Scalars["String"]
	author?: Maybe<ExtendedAuthor>
	createdAt: Scalars["DateTime"]
	expiryAt: Scalars["DateTime"]
	tagDecimalId: Scalars["String"]
	tagHexId: Scalars["String"]
	type: TagTypes
	updatedAt: Scalars["DateTime"]
}

export type ExtendedTrendingSkill = {
	__typename?: "ExtendedTrendingSkill"
	_id: Scalars["String"]
	author: ExtendedAuthor
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	links: Scalars["String"]
	maxAge?: Maybe<Scalars["Float"]>
	minAge?: Maybe<Scalars["Float"]>
	name: Scalars["String"]
	topic: Topic
	topicId: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type ExtendedUnauthorizedClass = {
	__typename?: "ExtendedUnauthorizedClass"
	class: Class
	classId: Scalars["String"]
	classSection: ClassSection
	classSectionId: Scalars["String"]
}

export type ExtendedWorkshop = {
	__typename?: "ExtendedWorkshop"
	_id: Scalars["String"]
	benefit: Scalars["String"]
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	enrolledUsers?: Maybe<Array<Author>>
	isActive: Scalars["Boolean"]
	isLive: Scalars["Boolean"]
	isPaid: Scalars["Boolean"]
	isPublic: Scalars["Boolean"]
	priceInINR?: Maybe<Scalars["Float"]>
	ratings?: Maybe<Array<Rating>>
	recommendations?: Maybe<Array<Recommendation>>
	sessions?: Maybe<Array<WorkshopSession>>
	thumbnail?: Maybe<EmbeddedPicture>
	title: Scalars["String"]
	trainers?: Maybe<Array<Trainer>>
	updatedAt: Scalars["DateTime"]
}

export type ExtendedWorkshopSession = {
	__typename?: "ExtendedWorkshopSession"
	_id: Scalars["String"]
	attachments?: Maybe<MediaAttachments>
	benefit: Scalars["String"]
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	isActive: Scalars["Boolean"]
	liveSessionUrl?: Maybe<Scalars["String"]>
	ratings?: Maybe<Array<Rating>>
	thumbnail?: Maybe<EmbeddedPicture>
	title: Scalars["String"]
	trainers?: Maybe<Array<Trainer>>
	trainersIds?: Maybe<Array<Scalars["String"]>>
	updatedAt: Scalars["DateTime"]
	video?: Maybe<EmbeddedVideo>
	workshop: Workshop
	workshopId: Scalars["String"]
}

export type FeedPostInput = {
	attachments?: InputMaybe<MediaAttachmentsInput>
	text?: InputMaybe<Scalars["String"]>
}

export type FeedPostLike = {
	__typename?: "FeedPostLike"
	author: Author
	createdAt: Scalars["DateTime"]
	updatedAt: Scalars["DateTime"]
}

export type FeedPostWithFieldErrorsResponse = {
	__typename?: "FeedPostWithFieldErrorsResponse"
	errors?: Maybe<Array<FieldError>>
	feedPost?: Maybe<ExtendedFeedPost>
}

export type FieldError = {
	__typename?: "FieldError"
	error: Scalars["String"]
	field: Scalars["String"]
}

export type FinishUploadInput = {
	key: Scalars["String"]
}

export type FinishUploadResponse = {
	__typename?: "FinishUploadResponse"
	type: MediaTypes
	url: Scalars["String"]
}

export type Gallery = {
	__typename?: "Gallery"
	_id: Scalars["String"]
	attachments: MediaAttachments
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	title: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type GalleryInput = {
	attachments: MediaAttachmentsInput
	description: Scalars["String"]
	title: Scalars["String"]
}

export type GalleryWithFieldErrorsResponse = {
	__typename?: "GalleryWithFieldErrorsResponse"
	errors?: Maybe<Array<FieldError>>
	gallery?: Maybe<Gallery>
}

export enum Genders {
	Female = "Female",
	Male = "Male",
	Others = "Others",
}

export type Holiday = {
	__typename?: "Holiday"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	endDate: Scalars["DateTime"]
	eventType?: Maybe<Scalars["String"]>
	name: Scalars["String"]
	startDate: Scalars["DateTime"]
	updatedAt: Scalars["DateTime"]
}

export type HolidayInput = {
	endDate: Scalars["DateTime"]
	eventType?: InputMaybe<Scalars["String"]>
	name: Scalars["String"]
	startDate: Scalars["DateTime"]
}

export type HolidayWithFieldErrorsResponse = {
	__typename?: "HolidayWithFieldErrorsResponse"
	errors?: Maybe<Array<FieldError>>
	holiday?: Maybe<Holiday>
}

export type House = {
	__typename?: "House"
	_id: Scalars["String"]
	captainId?: Maybe<Scalars["String"]>
	colorHex: Scalars["String"]
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	pointsBalance?: Maybe<Scalars["Float"]>
	updatedAt: Scalars["DateTime"]
}

export type HouseInput = {
	colorHex: Scalars["String"]
	name: Scalars["String"]
}

export type HouseWithFieldErrorsResponse = {
	__typename?: "HouseWithFieldErrorsResponse"
	errors?: Maybe<Array<FieldError>>
	house?: Maybe<ExtendedHouse>
}

export type InventoryResponse = {
	__typename?: "InventoryResponse"
	stockReaders: Scalars["Float"]
	stockTags: Scalars["Float"]
}

export type LabelledRestrictedEntriesResponse = {
	__typename?: "LabelledRestrictedEntriesResponse"
	label: Scalars["String"]
	restrictedEntries: Array<ExtendedRestrictedEntryRecord>
}

export type LabelledStaffAttendance = {
	__typename?: "LabelledStaffAttendance"
	attendance: ExtendedStaffAttendance
	label: Scalars["String"]
}

export type LabelledStaffAttendances = {
	__typename?: "LabelledStaffAttendances"
	attendances: Array<LabelledStaffAttendance>
	staff: Staff
}

export type LabelledStaffAttendancesResponse = {
	__typename?: "LabelledStaffAttendancesResponse"
	attendances: Array<ExtendedStaffAttendance>
	label: Scalars["String"]
}

export type LabelledStudentAttendance = {
	__typename?: "LabelledStudentAttendance"
	attendance: ExtendedStudentAttendance
	label: Scalars["String"]
}

export type LabelledStudentAttendances = {
	__typename?: "LabelledStudentAttendances"
	attendances: Array<LabelledStudentAttendance>
	student: ExtendedStudent
}

export type LabelledStudentAttendancesResponse = {
	__typename?: "LabelledStudentAttendancesResponse"
	attendances: Array<ExtendedStudentAttendance>
	label: Scalars["String"]
}

export type LabelledStudentTelegramMessage = {
	__typename?: "LabelledStudentTelegramMessage"
	label: Scalars["String"]
	messages: Array<TelegramMessage>
}

export type LabelledStudentTelegramMessages = {
	__typename?: "LabelledStudentTelegramMessages"
	messages: Array<LabelledStudentTelegramMessage>
	student: ExtendedStudent
}

export type Links = {
	__typename?: "Links"
	custom?: Maybe<Array<Scalars["String"]>>
	youtube?: Maybe<Array<Scalars["String"]>>
}

export type Location = {
	__typename?: "Location"
	address: Scalars["String"]
	city: Scalars["String"]
	pin: Scalars["Float"]
	state: Scalars["String"]
}

export type MediaAttachments = {
	__typename?: "MediaAttachments"
	pdfs?: Maybe<Array<EmbeddedPdf>>
	pictures?: Maybe<Array<EmbeddedPicture>>
	videos?: Maybe<Array<EmbeddedVideo>>
}

export type MediaAttachmentsInput = {
	pdfs?: InputMaybe<Array<PdfInput>>
	pictures?: InputMaybe<Array<PictureInput>>
	videos?: InputMaybe<Array<VideoInput>>
}

export enum MediaTypes {
	Doc = "DOC",
	Image = "Image",
	Pdf = "PDF",
	Video = "Video",
}

export type Mutation = {
	__typename?: "Mutation"
	activateWorkshop: Scalars["Boolean"]
	activateWorkshopSession: Scalars["Boolean"]
	addAttachmentToGallery: Gallery
	addAttachmentToResource: Resource
	addAttachmentToShowcase: Showcase
	addClassTeacher: ExtendedClassSection
	addHouseCaptain: ExtendedHouse
	addReader: Reader
	addReaderToRestrictedZone: Reader
	addShowcaseAuthor?: Maybe<Showcase>
	addUnauthorizedClass: ExtendedUnauthorizedClass
	addUnauthorizedDepartments: Array<Department>
	addWorkshopSession: WorkshopSessionWithFieldErrorsResponse
	addWorkshopSessionTrainer?: Maybe<WorkshopSession>
	approveFeedPost: ExtendedFeedPost
	approvePointsRedeemTransaction: Scalars["Boolean"]
	assignNewTag: Tag
	assignNewTagToStaff: Tag
	changePassword: Scalars["Boolean"]
	createBusRoute: BusRouteWithFieldErrorsResponse
	createCircular: CircularWithFieldErrorsResponse
	createClass: ClassWithFieldErrorsResponse
	createClassSection: ClassSectionWithFieldErrorsResponse
	createDepartment: DepartmentWithFieldErrorsResponse
	createFeedPost: FeedPostWithFieldErrorsResponse
	createGallery: GalleryWithFieldErrorsResponse
	createHoliday: HolidayWithFieldErrorsResponse
	createHouse: HouseWithFieldErrorsResponse
	createPenaltyCategory: PenaltyCategoryWithFieldErrorsResponse
	createPenaltyItem: PenaltyItemWithFieldErrorsResponse
	createPointsRedeemItem: PointsRedeemItemWithFieldErrorsResponse
	createResource: ResourceWithFieldErrorsResponse
	createRestrictedZone: RestrictedZone
	createRewardCategory: RewardCategoryWithFieldErrorsResponse
	createRewardItem: RewardItemWithFieldErrorsResponse
	createShowcase: ShowcaseWithFieldErrorsResponse
	createStaff: StaffWithFieldErrorsResponse
	createStudent: StudentWithFieldErrorsResponse
	createSubTopic: TopicWithFieldErrorsResponse
	createSubject: SubjectWithFieldErrorsResponse
	createTopic: TopicWithFieldErrorsResponse
	createTrainer: TrainerWithFieldErrorsResponse
	createTrendingSkill: TrendingSkillWithFieldErrorsResponse
	createWorkshop: WorkshopWithFieldErrorsResponse
	deactivateWorkshop: Scalars["Boolean"]
	deactivateWorkshopSession: Scalars["Boolean"]
	deleteAttachmentFromGallery: Gallery
	deleteAttachmentFromResource: Resource
	deleteAttachmentFromShowcase: Showcase
	deleteBusRoute: Scalars["Boolean"]
	deleteFeedPost: Scalars["Boolean"]
	deleteGallery: Scalars["Boolean"]
	deleteHoliday: Scalars["Boolean"]
	deleteResource: Scalars["Boolean"]
	deleteShowcase: Scalars["Boolean"]
	deleteShowcaseAuthor?: Maybe<Showcase>
	deleteTrendingSkill: Scalars["Boolean"]
	deleteWorkshopSessionTrainer?: Maybe<WorkshopSession>
	dislikeFeedPost: ExtendedFeedPost
	editBusRoute: BusRouteWithFieldErrorsResponse
	editClassOfStudent: StudentWithFieldErrorsResponse
	editGallery: GalleryWithFieldErrorsResponse
	editHoliday: HolidayWithFieldErrorsResponse
	editHouse: HouseWithFieldErrorsResponse
	editHouseOfStudent: StudentWithFieldErrorsResponse
	editPenaltyCategory: PenaltyCategoryWithFieldErrorsResponse
	editPenaltyItem: PenaltyItemWithFieldErrorsResponse
	editPointsRedeemItem: PointsRedeemItemWithFieldErrorsResponse
	editReaderLabel: Reader
	editResource: ResourceWithFieldErrorsResponse
	editRestrictedZone: RestrictedZone
	editRewardCategory: RewardCategoryWithFieldErrorsResponse
	editRewardItem: RewardItemWithFieldErrorsResponse
	editShowcase: ShowcaseWithFieldErrorsResponse
	editStaff: StaffWithFieldErrorsResponse
	editStudent: StudentWithFieldErrorsResponse
	editTrainer: TrainerWithFieldErrorsResponse
	editTrendingSkill: TrendingSkillWithFieldErrorsResponse
	editWorkshop: WorkshopWithFieldErrorsResponse
	editWorkshopSession: WorkshopSessionWithFieldErrorsResponse
	finishPdfUpload: FinishUploadResponse
	finishPictureUpload: FinishUploadResponse
	finishVideoUpload: FinishUploadResponse
	generateStaffAbsentsAttendanceReport: Array<LabelledStaffAttendancesResponse>
	generateStaffCompleteAttendanceReport: StaffAttendanceReportResponse
	generateStaffLateComersAttendanceReport: Array<LabelledStaffAttendancesResponse>
	generateStaffRestrictedEntriesReport: Array<LabelledRestrictedEntriesResponse>
	generateStudentAbsentsAttendanceReport: Array<LabelledStudentAttendancesResponse>
	generateStudentCompleteAttendanceReport: StudentAttendanceReportResponse
	generateStudentLateComersAttendanceReport: Array<LabelledStudentAttendancesResponse>
	generateStudentRestrictedEntriesReport: Array<LabelledRestrictedEntriesResponse>
	generateStudentTelegramMessagesReport: StudentTelegramMessageReportResponse
	likeFeedPost: ExtendedFeedPost
	login: AuthResponse
	logout: Scalars["Boolean"]
	markManualIn: ExtendedStudentAttendance
	markManualOut: ExtendedStudentAttendance
	markParentPickup: ExtendedStudentAttendance
	markStaffManualIn: StaffAttendance
	markStaffManualOut: StaffAttendance
	penaliseDepartments: ExtendedPenalty
	penaliseHouses: ExtendedPenalty
	penaliseStaff: ExtendedPenalty
	penaliseStudents: ExtendedPenalty
	rejectPointsRedeemTransaction: Scalars["Boolean"]
	removeClass: Scalars["Boolean"]
	removeClassSection: Scalars["Boolean"]
	removeClassTeacher: ExtendedClassSection
	removeDepartment: Scalars["Boolean"]
	removeFeedPost: Scalars["Boolean"]
	removeHouse: Scalars["Boolean"]
	removeHouseCaptain: ExtendedHouse
	removeReader: Scalars["Boolean"]
	removeReaderFromRestrictedZone: Scalars["Boolean"]
	removeSubject: Scalars["Boolean"]
	removeTopic: Scalars["Boolean"]
	removeUnauthorizedClass: Scalars["Boolean"]
	removeUnauthorizedDepartment: Scalars["Boolean"]
	resetPasswordForStaff: Scalars["Boolean"]
	resetPasswordForStudent: Scalars["Boolean"]
	rewardDepartments: ExtendedReward
	rewardHouses: ExtendedReward
	rewardStaff: ExtendedReward
	rewardStudents: ExtendedReward
	setAvatar: School
	setCover: School
	setStaffAvatar: EmbeddedPicture
	setStudentAvatar: EmbeddedPicture
	setTrainerAvatar: EmbeddedPicture
	setWorkshopSessionThumbnail: EmbeddedPicture
	setWorkshopThumbnail: EmbeddedPicture
	signPdfUpload: UploadSignatureResponse
	signPictureUpload: UploadSignatureResponse
	signVideoUpload: UploadSignatureResponse
	updateAttendanceTiming: ExtendedClassSection
	updateAttendanceTimingOfDepartment: Department
	updateClass: ClassWithFieldErrorsResponse
	updateTopic?: Maybe<Topic>
	updateWorkingWeekdays: ExtendedClassSection
	updateWorkingWeekdaysOfDepartment: Department
	whitelistStaffs: Array<ExtendedStaff>
	whitelistStudents: Array<ExtendedStudent>
}

export type MutationActivateWorkshopArgs = {
	workshopId: Scalars["String"]
}

export type MutationActivateWorkshopSessionArgs = {
	workshopSessionId: Scalars["String"]
}

export type MutationAddAttachmentToGalleryArgs = {
	galleryId: Scalars["String"]
	input: MediaAttachmentsInput
}

export type MutationAddAttachmentToResourceArgs = {
	input: MediaAttachmentsInput
	resourceId: Scalars["String"]
}

export type MutationAddAttachmentToShowcaseArgs = {
	input: MediaAttachmentsInput
	showcaseId: Scalars["String"]
}

export type MutationAddClassTeacherArgs = {
	classId: Scalars["String"]
	classSectionId: Scalars["String"]
	teacherId: Scalars["String"]
}

export type MutationAddHouseCaptainArgs = {
	houseId: Scalars["String"]
	studentId: Scalars["String"]
}

export type MutationAddReaderArgs = {
	readerDecimalId: Scalars["String"]
}

export type MutationAddReaderToRestrictedZoneArgs = {
	readerDecimalId: Scalars["String"]
	restrictedZoneId: Scalars["String"]
}

export type MutationAddShowcaseAuthorArgs = {
	input: ShowcaseAuthorInput
	showcaseId: Scalars["String"]
}

export type MutationAddUnauthorizedClassArgs = {
	classId: Scalars["String"]
	classSectionId: Scalars["String"]
	restrictedZoneId: Scalars["String"]
}

export type MutationAddUnauthorizedDepartmentsArgs = {
	departmentIds: Array<Scalars["String"]>
	restrictedZoneId: Scalars["String"]
}

export type MutationAddWorkshopSessionArgs = {
	input: WorkshopSessionInput
	workshopId: Scalars["String"]
}

export type MutationAddWorkshopSessionTrainerArgs = {
	trainerId: Scalars["String"]
	workshopSessionId: Scalars["String"]
}

export type MutationApproveFeedPostArgs = {
	feedPostId: Scalars["String"]
}

export type MutationApprovePointsRedeemTransactionArgs = {
	transactionId: Scalars["String"]
}

export type MutationAssignNewTagArgs = {
	studentId: Scalars["String"]
	tagHexOrDecId: Scalars["String"]
}

export type MutationAssignNewTagToStaffArgs = {
	staffId: Scalars["String"]
	tagHexOrDecId: Scalars["String"]
}

export type MutationChangePasswordArgs = {
	currentPassword: Scalars["String"]
	newPassword: Scalars["String"]
	shouldLogOutOnOtherDevices?: InputMaybe<Scalars["Boolean"]>
}

export type MutationCreateBusRouteArgs = {
	input: BusRouteInput
}

export type MutationCreateCircularArgs = {
	input: CircularInput
}

export type MutationCreateClassArgs = {
	input: ClassInput
}

export type MutationCreateClassSectionArgs = {
	input: ClassSectionInput
}

export type MutationCreateDepartmentArgs = {
	input: DepartmentInput
}

export type MutationCreateFeedPostArgs = {
	input: FeedPostInput
}

export type MutationCreateGalleryArgs = {
	input: GalleryInput
}

export type MutationCreateHolidayArgs = {
	input: HolidayInput
}

export type MutationCreateHouseArgs = {
	input: HouseInput
}

export type MutationCreatePenaltyCategoryArgs = {
	input: PenaltyCategoryInput
}

export type MutationCreatePenaltyItemArgs = {
	input: PenaltyItemInput
}

export type MutationCreatePointsRedeemItemArgs = {
	input: PointsRedeemItemInput
}

export type MutationCreateResourceArgs = {
	input: ResourceInput
}

export type MutationCreateRestrictedZoneArgs = {
	input: RestrictedZoneInput
}

export type MutationCreateRewardCategoryArgs = {
	input: RewardCategoryInput
}

export type MutationCreateRewardItemArgs = {
	input: RewardItemInput
}

export type MutationCreateShowcaseArgs = {
	input: ShowcaseInput
}

export type MutationCreateStaffArgs = {
	input: StaffInput
}

export type MutationCreateStudentArgs = {
	input: StudentInput
}

export type MutationCreateSubTopicArgs = {
	subTopic: Scalars["String"]
	topicId: Scalars["String"]
}

export type MutationCreateSubjectArgs = {
	subjectName: Scalars["String"]
}

export type MutationCreateTopicArgs = {
	topicName: Scalars["String"]
}

export type MutationCreateTrainerArgs = {
	input: TrainerInput
}

export type MutationCreateTrendingSkillArgs = {
	input: TrendingSkillInput
}

export type MutationCreateWorkshopArgs = {
	input: WorkshopInput
}

export type MutationDeactivateWorkshopArgs = {
	workshopId: Scalars["String"]
}

export type MutationDeactivateWorkshopSessionArgs = {
	workshopSessionId: Scalars["String"]
}

export type MutationDeleteAttachmentFromGalleryArgs = {
	galleryId: Scalars["String"]
	input: MediaAttachmentsInput
}

export type MutationDeleteAttachmentFromResourceArgs = {
	input: MediaAttachmentsInput
	resourceId: Scalars["String"]
}

export type MutationDeleteAttachmentFromShowcaseArgs = {
	input: MediaAttachmentsInput
	showcaseId: Scalars["String"]
}

export type MutationDeleteBusRouteArgs = {
	busRouteId: Scalars["String"]
}

export type MutationDeleteFeedPostArgs = {
	feedPostId: Scalars["String"]
}

export type MutationDeleteGalleryArgs = {
	galleryId: Scalars["String"]
}

export type MutationDeleteHolidayArgs = {
	holidayId: Scalars["String"]
}

export type MutationDeleteResourceArgs = {
	resourceId: Scalars["String"]
}

export type MutationDeleteShowcaseArgs = {
	showcaseId: Scalars["String"]
}

export type MutationDeleteShowcaseAuthorArgs = {
	authorId: Scalars["String"]
	showcaseId: Scalars["String"]
}

export type MutationDeleteTrendingSkillArgs = {
	trendingSkillId: Scalars["String"]
}

export type MutationDeleteWorkshopSessionTrainerArgs = {
	trainerId: Scalars["String"]
	workshopSessionId: Scalars["String"]
}

export type MutationDislikeFeedPostArgs = {
	feedPostId: Scalars["String"]
}

export type MutationEditBusRouteArgs = {
	busRouteId: Scalars["String"]
	input: BusRouteInput
}

export type MutationEditClassOfStudentArgs = {
	input: EditStudentClassInput
	studentId: Scalars["String"]
}

export type MutationEditGalleryArgs = {
	galleryId: Scalars["String"]
	input: EditGalleryInput
}

export type MutationEditHolidayArgs = {
	holidayId: Scalars["String"]
	input: HolidayInput
}

export type MutationEditHouseArgs = {
	houseId: Scalars["String"]
	input: HouseInput
}

export type MutationEditHouseOfStudentArgs = {
	houseId: Scalars["String"]
	studentId: Scalars["String"]
}

export type MutationEditPenaltyCategoryArgs = {
	input: EditPenaltyCategoryInput
	penaltyCategoryId: Scalars["String"]
}

export type MutationEditPenaltyItemArgs = {
	input: EditPenaltyItemInput
	penaltyItemId: Scalars["String"]
}

export type MutationEditPointsRedeemItemArgs = {
	input: EditPointsRedeemItemInput
	pointsRedeemItemId: Scalars["String"]
}

export type MutationEditReaderLabelArgs = {
	label: Scalars["String"]
	readerId: Scalars["String"]
}

export type MutationEditResourceArgs = {
	input: EditResourceInput
	resourceId: Scalars["String"]
}

export type MutationEditRestrictedZoneArgs = {
	input: RestrictedZoneInput
	restrictedZoneId: Scalars["String"]
}

export type MutationEditRewardCategoryArgs = {
	input: EditRewardCategoryInput
	rewardCategoryId: Scalars["String"]
}

export type MutationEditRewardItemArgs = {
	input: EditRewardItemInput
	rewardItemId: Scalars["String"]
}

export type MutationEditShowcaseArgs = {
	input: EditShowcaseInput
	showcaseId: Scalars["String"]
}

export type MutationEditStaffArgs = {
	input: EditStaffInput
	staffId: Scalars["String"]
}

export type MutationEditStudentArgs = {
	input: EditStudentInput
	studentId: Scalars["String"]
}

export type MutationEditTrainerArgs = {
	input: TrainerInput
	trainerId: Scalars["String"]
}

export type MutationEditTrendingSkillArgs = {
	input: TrendingSkillInput
	trendingSkillId: Scalars["String"]
}

export type MutationEditWorkshopArgs = {
	input: WorkshopInput
	workshopId: Scalars["String"]
}

export type MutationEditWorkshopSessionArgs = {
	input: WorkshopSessionInput
	workshopId: Scalars["String"]
	workshopSessionId: Scalars["String"]
}

export type MutationFinishPdfUploadArgs = {
	input: FinishUploadInput
}

export type MutationFinishPictureUploadArgs = {
	input: FinishUploadInput
}

export type MutationFinishVideoUploadArgs = {
	input: FinishUploadInput
}

export type MutationGenerateStaffAbsentsAttendanceReportArgs = {
	input: StaffReportInput
}

export type MutationGenerateStaffCompleteAttendanceReportArgs = {
	input: StaffReportInput
}

export type MutationGenerateStaffLateComersAttendanceReportArgs = {
	input: StaffReportInput
}

export type MutationGenerateStaffRestrictedEntriesReportArgs = {
	input: StaffReportInput
}

export type MutationGenerateStudentAbsentsAttendanceReportArgs = {
	input: StudentsReportInput
}

export type MutationGenerateStudentCompleteAttendanceReportArgs = {
	input: StudentsReportInput
}

export type MutationGenerateStudentLateComersAttendanceReportArgs = {
	input: StudentsReportInput
}

export type MutationGenerateStudentRestrictedEntriesReportArgs = {
	input: StudentsReportInput
}

export type MutationGenerateStudentTelegramMessagesReportArgs = {
	input: StudentsReportInput
}

export type MutationLikeFeedPostArgs = {
	feedPostId: Scalars["String"]
}

export type MutationLoginArgs = {
	password: Scalars["String"]
	username: Scalars["String"]
}

export type MutationMarkManualInArgs = {
	inAt: Scalars["DateTime"]
	shouldNotifyIn: Scalars["Boolean"]
	studentId: Scalars["String"]
}

export type MutationMarkManualOutArgs = {
	outAt: Scalars["DateTime"]
	studentId: Scalars["String"]
}

export type MutationMarkParentPickupArgs = {
	tagHexId: Scalars["String"]
}

export type MutationMarkStaffManualInArgs = {
	inAt: Scalars["DateTime"]
	staffId: Scalars["String"]
}

export type MutationMarkStaffManualOutArgs = {
	outAt: Scalars["DateTime"]
	staffId: Scalars["String"]
}

export type MutationPenaliseDepartmentsArgs = {
	departmentIds: Array<Scalars["String"]>
	penaltyItemIds: Array<Scalars["String"]>
}

export type MutationPenaliseHousesArgs = {
	houseIds: Array<Scalars["String"]>
	penaltyItemIds: Array<Scalars["String"]>
}

export type MutationPenaliseStaffArgs = {
	penaltyItemIds: Array<Scalars["String"]>
	staffIds: Array<Scalars["String"]>
}

export type MutationPenaliseStudentsArgs = {
	penaltyItemIds: Array<Scalars["String"]>
	studentIds: Array<Scalars["String"]>
}

export type MutationRejectPointsRedeemTransactionArgs = {
	remarks: Scalars["String"]
	transactionId: Scalars["String"]
}

export type MutationRemoveClassArgs = {
	classId: Scalars["String"]
}

export type MutationRemoveClassSectionArgs = {
	classId: Scalars["String"]
	classSectionId: Scalars["String"]
}

export type MutationRemoveClassTeacherArgs = {
	classId: Scalars["String"]
	classSectionId: Scalars["String"]
	teacherId: Scalars["String"]
}

export type MutationRemoveDepartmentArgs = {
	departmentId: Scalars["String"]
}

export type MutationRemoveFeedPostArgs = {
	feedPostId: Scalars["String"]
}

export type MutationRemoveHouseArgs = {
	houseId: Scalars["String"]
}

export type MutationRemoveHouseCaptainArgs = {
	houseId: Scalars["String"]
	studentId: Scalars["String"]
}

export type MutationRemoveReaderArgs = {
	readerDecimalId: Scalars["String"]
}

export type MutationRemoveReaderFromRestrictedZoneArgs = {
	readerId: Scalars["String"]
	restrictedZoneId: Scalars["String"]
}

export type MutationRemoveSubjectArgs = {
	subjectId: Scalars["String"]
}

export type MutationRemoveTopicArgs = {
	topicId: Scalars["String"]
}

export type MutationRemoveUnauthorizedClassArgs = {
	classId: Scalars["String"]
	classSectionId: Scalars["String"]
	restrictedZoneId: Scalars["String"]
}

export type MutationRemoveUnauthorizedDepartmentArgs = {
	departmentId: Scalars["String"]
	restrictedZoneId: Scalars["String"]
}

export type MutationResetPasswordForStaffArgs = {
	staffId: Scalars["String"]
	staffUsername: Scalars["String"]
}

export type MutationResetPasswordForStudentArgs = {
	studentId: Scalars["String"]
	studentUsername: Scalars["String"]
}

export type MutationRewardDepartmentsArgs = {
	departmentIds: Array<Scalars["String"]>
	rewardItemIds: Array<Scalars["String"]>
}

export type MutationRewardHousesArgs = {
	houseIds: Array<Scalars["String"]>
	rewardItemIds: Array<Scalars["String"]>
}

export type MutationRewardStaffArgs = {
	rewardItemIds: Array<Scalars["String"]>
	staffIds: Array<Scalars["String"]>
}

export type MutationRewardStudentsArgs = {
	rewardItemIds: Array<Scalars["String"]>
	studentIds: Array<Scalars["String"]>
}

export type MutationSetAvatarArgs = {
	key: Scalars["String"]
	url: Scalars["String"]
}

export type MutationSetCoverArgs = {
	key: Scalars["String"]
	url: Scalars["String"]
}

export type MutationSetStaffAvatarArgs = {
	key: Scalars["String"]
	staffId: Scalars["String"]
	url: Scalars["String"]
}

export type MutationSetStudentAvatarArgs = {
	key: Scalars["String"]
	studentId: Scalars["String"]
	url: Scalars["String"]
}

export type MutationSetTrainerAvatarArgs = {
	key: Scalars["String"]
	trainerId: Scalars["String"]
	url: Scalars["String"]
}

export type MutationSetWorkshopSessionThumbnailArgs = {
	key: Scalars["String"]
	url: Scalars["String"]
	workshopSessionId: Scalars["String"]
}

export type MutationSetWorkshopThumbnailArgs = {
	key: Scalars["String"]
	url: Scalars["String"]
	workshopId: Scalars["String"]
}

export type MutationSignPictureUploadArgs = {
	format: Scalars["String"]
}

export type MutationSignVideoUploadArgs = {
	format: Scalars["String"]
}

export type MutationUpdateAttendanceTimingArgs = {
	attendanceTiming: AttendanceTimingInput
	classId: Scalars["String"]
	classSectionId: Scalars["String"]
}

export type MutationUpdateAttendanceTimingOfDepartmentArgs = {
	attendanceTiming: StaffAttendanceTimingInput
	departmentId: Scalars["String"]
}

export type MutationUpdateClassArgs = {
	classId: Scalars["String"]
	input: ClassInput
}

export type MutationUpdateTopicArgs = {
	topicId: Scalars["String"]
	topicName: Scalars["String"]
}

export type MutationUpdateWorkingWeekdaysArgs = {
	classId: Scalars["String"]
	classSectionId: Scalars["String"]
	workingWeekdays: Array<Scalars["String"]>
}

export type MutationUpdateWorkingWeekdaysOfDepartmentArgs = {
	departmentId: Scalars["String"]
	workingWeekdays: Array<Scalars["String"]>
}

export type MutationWhitelistStaffsArgs = {
	staffIds: Array<Scalars["String"]>
}

export type MutationWhitelistStudentsArgs = {
	studentIds: Array<Scalars["String"]>
}

export type PdfInput = {
	key: Scalars["String"]
	url: Scalars["String"]
}

export type PaginatedBusRoutesResponse = {
	__typename?: "PaginatedBusRoutesResponse"
	busRoutes?: Maybe<Array<BusRoute>>
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedCircularsResponse = {
	__typename?: "PaginatedCircularsResponse"
	circulars?: Maybe<Array<Circular>>
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedClassesResponse = {
	__typename?: "PaginatedClassesResponse"
	classes?: Maybe<Array<ExtendedClass>>
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedDepartmentsResponse = {
	__typename?: "PaginatedDepartmentsResponse"
	currentPage: Scalars["Float"]
	departments?: Maybe<Array<Department>>
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedFeedPostsResponse = {
	__typename?: "PaginatedFeedPostsResponse"
	currentPage: Scalars["Float"]
	feedPosts?: Maybe<Array<ExtendedFeedPost>>
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedGalleryResponse = {
	__typename?: "PaginatedGalleryResponse"
	currentPage: Scalars["Float"]
	gallery?: Maybe<Array<Gallery>>
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedHolidaysResponse = {
	__typename?: "PaginatedHolidaysResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	holidays?: Maybe<Array<Holiday>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedHousesResponse = {
	__typename?: "PaginatedHousesResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	houses?: Maybe<Array<ExtendedHouse>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedInput = {
	limit?: InputMaybe<Scalars["Int"]>
	page?: InputMaybe<Scalars["Float"]>
}

export type PaginatedPenaltyCategoriesResponse = {
	__typename?: "PaginatedPenaltyCategoriesResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	penaltyCategories?: Maybe<Array<ExtendedPenaltyCategory>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedPenaltyItemsResponse = {
	__typename?: "PaginatedPenaltyItemsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	penaltyItems?: Maybe<Array<ExtendedPenaltyItem>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedPointsRedeemItemsResponse = {
	__typename?: "PaginatedPointsRedeemItemsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	pointsRedeemItems?: Maybe<Array<PointsRedeemItem>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedPointsRedeemTransactionsResponse = {
	__typename?: "PaginatedPointsRedeemTransactionsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
	transactions?: Maybe<Array<ExtendedPointsRedeemTransaction>>
}

export type PaginatedResourcesResponse = {
	__typename?: "PaginatedResourcesResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	resources?: Maybe<Array<ExtendedResource>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedRestrictedZonesResponse = {
	__typename?: "PaginatedRestrictedZonesResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	restrictedZones?: Maybe<Array<RestrictedZone>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedRewardCategoriesResponse = {
	__typename?: "PaginatedRewardCategoriesResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	rewardCategories?: Maybe<Array<ExtendedRewardCategory>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedRewardItemsResponse = {
	__typename?: "PaginatedRewardItemsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	rewardItems?: Maybe<Array<ExtendedRewardItem>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedShowcasesResponse = {
	__typename?: "PaginatedShowcasesResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	showcases?: Maybe<Array<ExtendedShowcase>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedStaffAttendancesResponse = {
	__typename?: "PaginatedStaffAttendancesResponse"
	attendances?: Maybe<Array<StaffAttendance>>
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedStaffResponse = {
	__typename?: "PaginatedStaffResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	staffs?: Maybe<Array<ExtendedStaff>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedStockReadersResponse = {
	__typename?: "PaginatedStockReadersResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	readers?: Maybe<Array<StockReader>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedStockTagsResponse = {
	__typename?: "PaginatedStockTagsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	tags?: Maybe<Array<StockTag>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedStudentAttendancesResponse = {
	__typename?: "PaginatedStudentAttendancesResponse"
	attendances?: Maybe<Array<StudentAttendance>>
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedStudentsResponse = {
	__typename?: "PaginatedStudentsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	students?: Maybe<Array<ExtendedStudent>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedSubjectsResponse = {
	__typename?: "PaginatedSubjectsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	subjects?: Maybe<Array<Subject>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedTopicsResponse = {
	__typename?: "PaginatedTopicsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	topics?: Maybe<Array<Topic>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedTrainersResponse = {
	__typename?: "PaginatedTrainersResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
	trainers?: Maybe<Array<Trainer>>
}

export type PaginatedTrendingSkillsResponse = {
	__typename?: "PaginatedTrendingSkillsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
	trendingSkills?: Maybe<Array<ExtendedTrendingSkill>>
}

export type PaginatedWorkshopsResponse = {
	__typename?: "PaginatedWorkshopsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
	workshops?: Maybe<Array<ExtendedWorkshop>>
}

export type Parent = {
	__typename?: "Parent"
	email?: Maybe<Scalars["String"]>
	name?: Maybe<Scalars["String"]>
	nfcTagId?: Maybe<Scalars["String"]>
	phone?: Maybe<Scalars["String"]>
	picture?: Maybe<EmbeddedPicture>
}

export type ParentInput = {
	email?: InputMaybe<Scalars["String"]>
	name?: InputMaybe<Scalars["String"]>
	nfcTagId?: InputMaybe<Scalars["String"]>
	phone?: InputMaybe<Scalars["String"]>
}

export type PenaltyCategory = {
	__typename?: "PenaltyCategory"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	name: Scalars["String"]
	target: UserTargets
	updatedAt: Scalars["DateTime"]
}

export type PenaltyCategoryInput = {
	description: Scalars["String"]
	name: Scalars["String"]
	target: UserTargets
}

export type PenaltyCategoryWithFieldErrorsResponse = {
	__typename?: "PenaltyCategoryWithFieldErrorsResponse"
	errors?: Maybe<Array<FieldError>>
	penaltyCategory?: Maybe<PenaltyCategory>
}

export type PenaltyItem = {
	__typename?: "PenaltyItem"
	_id: Scalars["String"]
	categoryId: Scalars["String"]
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	name: Scalars["String"]
	points: Scalars["Float"]
	updatedAt: Scalars["DateTime"]
}

export type PenaltyItemInput = {
	categoryId: Scalars["String"]
	description: Scalars["String"]
	name: Scalars["String"]
	points: Scalars["Float"]
}

export type PenaltyItemWithFieldErrorsResponse = {
	__typename?: "PenaltyItemWithFieldErrorsResponse"
	errors?: Maybe<Array<FieldError>>
	penaltyItem?: Maybe<PenaltyItem>
}

export type PictureInput = {
	key: Scalars["String"]
	url: Scalars["String"]
}

export type PictureVariants = {
	__typename?: "PictureVariants"
	large?: Maybe<Scalars["String"]>
	small?: Maybe<Scalars["String"]>
	thumbnail?: Maybe<Scalars["String"]>
}

export type PointsRedeemItem = {
	__typename?: "PointsRedeemItem"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	name: Scalars["String"]
	points: Scalars["Float"]
	target: UserTargets
	updatedAt: Scalars["DateTime"]
}

export type PointsRedeemItemInput = {
	description: Scalars["String"]
	name: Scalars["String"]
	points: Scalars["Float"]
	target: UserTargets
}

export type PointsRedeemItemWithFieldErrorsResponse = {
	__typename?: "PointsRedeemItemWithFieldErrorsResponse"
	errors?: Maybe<Array<FieldError>>
	pointsRedeemItem?: Maybe<PointsRedeemItem>
}

export type Query = {
	__typename?: "Query"
	activeRestrictedEntries: Array<ExtendedRestrictedEntryRecord>
	allAttendancesOfStaff: PaginatedStaffAttendancesResponse
	allAttendancesOfStudent: PaginatedStudentAttendancesResponse
	allBusRoutes: PaginatedBusRoutesResponse
	allCirculars: PaginatedCircularsResponse
	allClasses: PaginatedClassesResponse
	allDepartments: PaginatedDepartmentsResponse
	allFeedPosts: PaginatedFeedPostsResponse
	allGallery: PaginatedGalleryResponse
	allHolidays: PaginatedHolidaysResponse
	allHouses: PaginatedHousesResponse
	allPenaltyCategoriesForStaff: PaginatedPenaltyCategoriesResponse
	allPenaltyCategoriesForStudents: PaginatedPenaltyCategoriesResponse
	allPenaltyItems: PaginatedPenaltyItemsResponse
	allPointsRedeemItemsForStaff: PaginatedPointsRedeemItemsResponse
	allPointsRedeemItemsForStudents: PaginatedPointsRedeemItemsResponse
	allPointsRedeemTransactions: PaginatedPointsRedeemTransactionsResponse
	allResources: PaginatedResourcesResponse
	allRestrictedZones: PaginatedRestrictedZonesResponse
	allRewardCategoriesForStaff: PaginatedRewardCategoriesResponse
	allRewardCategoriesForStudents: PaginatedRewardCategoriesResponse
	allRewardItems: PaginatedRewardItemsResponse
	allShowcases: PaginatedShowcasesResponse
	allStaffs: PaginatedStaffResponse
	allStudents: PaginatedStudentsResponse
	allSubjects: PaginatedSubjectsResponse
	allTopics: PaginatedTopicsResponse
	allTrainers: PaginatedTrainersResponse
	allTrendingSkills: PaginatedTrendingSkillsResponse
	allWorkshops: PaginatedWorkshopsResponse
	approvedPointsRedeemTransactions: PaginatedPointsRedeemTransactionsResponse
	attendanceStats: AttendanceStatsResponse
	busRouteById: BusRoute
	busRoutes: Array<BusRoute>
	circularById: Circular
	classById: ExtendedClass
	classSectionById: ExtendedClassSection
	classWiseAttendanceStats: Array<AttendanceDataResponse>
	classes: Array<ExtendedClass>
	departmentById: Department
	departments: Array<Department>
	departmentsWithMostPoints: Array<Department>
	feedPostById: ExtendedFeedPost
	galleryById: Gallery
	holidayById: Holiday
	houseById: ExtendedHouse
	houses: Array<House>
	housesWithMostPoints: Array<House>
	inventory: InventoryResponse
	me?: Maybe<School>
	monthlyAttendanceStats: Array<AttendanceDataResponse>
	myFeedPosts: PaginatedFeedPostsResponse
	notWhitelistedStaffsWithoutPagination: Array<Staff>
	notWhitelistedStudents: Array<ExtendedStudent>
	penaltiesOfStaff: Array<ExtendedPenalty>
	penaltiesOfStudent: Array<ExtendedPenalty>
	penaltyCategoriesForStaff: Array<PenaltyCategory>
	penaltyCategoriesForStudents: Array<PenaltyCategory>
	penaltyCategoryById?: Maybe<PenaltyCategory>
	penaltyItemById?: Maybe<ExtendedPenaltyItem>
	penaltyItems: Array<PenaltyItem>
	pendingPointsRedeemTransactions: PaginatedPointsRedeemTransactionsResponse
	pointsRedeemItemById?: Maybe<PointsRedeemItem>
	pointsRedeemItemsForStaff: Array<PointsRedeemItem>
	pointsRedeemItemsForStudents: Array<PointsRedeemItem>
	readersHealth?: Maybe<Array<Reader>>
	resourceById: ExtendedResource
	restrictedZoneById?: Maybe<ExtendedRestrictedZone>
	rewardCategoriesForStaff: Array<RewardCategory>
	rewardCategoriesForStudents: Array<RewardCategory>
	rewardCategoryById?: Maybe<RewardCategory>
	rewardItemById?: Maybe<ExtendedRewardItem>
	rewardItems: Array<RewardItem>
	rewardsOfStaff: Array<ExtendedReward>
	rewardsOfStudent: Array<ExtendedReward>
	searchBusRoute: PaginatedBusRoutesResponse
	searchCircular: PaginatedCircularsResponse
	searchClasses: PaginatedClassesResponse
	searchDepartments: PaginatedDepartmentsResponse
	searchGallery: PaginatedGalleryResponse
	searchHoliday: PaginatedHolidaysResponse
	searchHouses: PaginatedHousesResponse
	searchPenaltyCategoriesForStaff: PaginatedPenaltyCategoriesResponse
	searchPenaltyCategoriesForStudents: PaginatedPenaltyCategoriesResponse
	searchPenaltyItems: PaginatedPenaltyItemsResponse
	searchPointsRedeemItemsForStaff: PaginatedPointsRedeemItemsResponse
	searchPointsRedeemItemsForStudents: PaginatedPointsRedeemItemsResponse
	searchResource: PaginatedResourcesResponse
	searchRestrictedZones: PaginatedRestrictedZonesResponse
	searchRewardCategoriesForStaff: PaginatedRewardCategoriesResponse
	searchRewardCategoriesForStudents: PaginatedRewardCategoriesResponse
	searchRewardItems: PaginatedRewardItemsResponse
	searchShowcase: PaginatedShowcasesResponse
	searchStaffs: PaginatedStaffResponse
	searchStudents: PaginatedStudentsResponse
	searchSubject: PaginatedSubjectsResponse
	searchTopic: PaginatedTopicsResponse
	searchTrainers: PaginatedTrainersResponse
	searchTrendingSkill: PaginatedTrendingSkillsResponse
	searchWorkshops: PaginatedWorkshopsResponse
	showcaseById: ExtendedShowcase
	staffAttendanceStats: StaffAttendanceStatsResponse
	staffById: ExtendedStaff
	staffMonthlyAttendanceStats: Array<AttendanceDataResponse>
	staffsWithMostPoints: Array<Staff>
	staffsWithoutPagination: Array<Staff>
	statistics: StatisticsResponse
	stockReaders: PaginatedStockReadersResponse
	stockTags: PaginatedStockTagsResponse
	studentById: ExtendedStudent
	studentByParentTagHexId?: Maybe<Student>
	students: Array<ExtendedStudent>
	studentsWithMostPoints: Array<Student>
	subjectById: Subject
	subjectsWithoutPagination: Array<Subject>
	tagByHexId?: Maybe<ExtendedTag>
	todayAttendanceOfStaff?: Maybe<ExtendedStaffAttendance>
	todayAttendanceOfStudent?: Maybe<ExtendedStudentAttendance>
	todayBusAttendanceOfStaff?: Maybe<StaffBusAttendance>
	todayBusAttendanceOfStudent?: Maybe<StudentBusAttendance>
	topicById: Topic
	topicsWithoutPagination: Array<Topic>
	trainerById?: Maybe<Trainer>
	trainersWithoutPagination: Array<Trainer>
	trendingSkillById: ExtendedTrendingSkill
	unauthorizedClasses: Array<ExtendedUnauthorizedClass>
	unauthorizedDepartments: Array<Department>
	whitelistedStaffsWithoutPagination: Array<Staff>
	whitelistedStudents: Array<ExtendedStudent>
	workshopById?: Maybe<ExtendedWorkshop>
	workshopSessionById?: Maybe<ExtendedWorkshopSession>
}

export type QueryAllAttendancesOfStaffArgs = {
	pagination: PaginatedInput
	staffId: Scalars["String"]
}

export type QueryAllAttendancesOfStudentArgs = {
	pagination: PaginatedInput
	studentId: Scalars["String"]
}

export type QueryAllBusRoutesArgs = {
	pagination: PaginatedInput
}

export type QueryAllCircularsArgs = {
	pagination: PaginatedInput
}

export type QueryAllClassesArgs = {
	pagination: PaginatedInput
}

export type QueryAllDepartmentsArgs = {
	pagination: PaginatedInput
}

export type QueryAllFeedPostsArgs = {
	pagination: PaginatedInput
}

export type QueryAllGalleryArgs = {
	pagination: PaginatedInput
}

export type QueryAllHolidaysArgs = {
	pagination: PaginatedInput
}

export type QueryAllHousesArgs = {
	pagination: PaginatedInput
}

export type QueryAllPenaltyCategoriesForStaffArgs = {
	pagination: PaginatedInput
}

export type QueryAllPenaltyCategoriesForStudentsArgs = {
	pagination: PaginatedInput
}

export type QueryAllPenaltyItemsArgs = {
	pagination: PaginatedInput
}

export type QueryAllPointsRedeemItemsForStaffArgs = {
	pagination: PaginatedInput
}

export type QueryAllPointsRedeemItemsForStudentsArgs = {
	pagination: PaginatedInput
}

export type QueryAllPointsRedeemTransactionsArgs = {
	pagination: PaginatedInput
}

export type QueryAllResourcesArgs = {
	pagination: PaginatedInput
}

export type QueryAllRestrictedZonesArgs = {
	pagination: PaginatedInput
}

export type QueryAllRewardCategoriesForStaffArgs = {
	pagination: PaginatedInput
}

export type QueryAllRewardCategoriesForStudentsArgs = {
	pagination: PaginatedInput
}

export type QueryAllRewardItemsArgs = {
	pagination: PaginatedInput
}

export type QueryAllShowcasesArgs = {
	pagination: PaginatedInput
}

export type QueryAllStaffsArgs = {
	pagination: PaginatedInput
}

export type QueryAllStudentsArgs = {
	classId?: InputMaybe<Scalars["String"]>
	classSectionId?: InputMaybe<Scalars["String"]>
	pagination: PaginatedInput
}

export type QueryAllSubjectsArgs = {
	pagination: PaginatedInput
}

export type QueryAllTopicsArgs = {
	pagination: PaginatedInput
}

export type QueryAllTrainersArgs = {
	pagination: PaginatedInput
}

export type QueryAllTrendingSkillsArgs = {
	pagination: PaginatedInput
}

export type QueryAllWorkshopsArgs = {
	pagination: PaginatedInput
}

export type QueryApprovedPointsRedeemTransactionsArgs = {
	pagination: PaginatedInput
}

export type QueryAttendanceStatsArgs = {
	classId?: InputMaybe<Scalars["String"]>
	classSectionId?: InputMaybe<Scalars["String"]>
}

export type QueryBusRouteByIdArgs = {
	busRouteId: Scalars["String"]
}

export type QueryCircularByIdArgs = {
	circularId: Scalars["String"]
}

export type QueryClassByIdArgs = {
	classId: Scalars["String"]
}

export type QueryClassSectionByIdArgs = {
	classSectionId: Scalars["String"]
}

export type QueryDepartmentByIdArgs = {
	departmentId: Scalars["String"]
}

export type QueryFeedPostByIdArgs = {
	feedPostId: Scalars["String"]
}

export type QueryGalleryByIdArgs = {
	galleryId: Scalars["String"]
}

export type QueryHolidayByIdArgs = {
	holidayId: Scalars["String"]
}

export type QueryHouseByIdArgs = {
	houseId: Scalars["String"]
}

export type QueryMonthlyAttendanceStatsArgs = {
	classId?: InputMaybe<Scalars["String"]>
	classSectionId?: InputMaybe<Scalars["String"]>
}

export type QueryMyFeedPostsArgs = {
	pagination: PaginatedInput
}

export type QueryNotWhitelistedStaffsWithoutPaginationArgs = {
	departmentId?: InputMaybe<Scalars["String"]>
	departmentType?: InputMaybe<DepartmentTypes>
	keyword?: InputMaybe<Scalars["String"]>
}

export type QueryNotWhitelistedStudentsArgs = {
	classId?: InputMaybe<Scalars["String"]>
	classSectionId?: InputMaybe<Scalars["String"]>
	keyword?: InputMaybe<Scalars["String"]>
}

export type QueryPenaltiesOfStaffArgs = {
	staffId: Scalars["String"]
}

export type QueryPenaltiesOfStudentArgs = {
	studentId: Scalars["String"]
}

export type QueryPenaltyCategoryByIdArgs = {
	penaltyCategoryId: Scalars["String"]
}

export type QueryPenaltyItemByIdArgs = {
	penaltyItemId: Scalars["String"]
}

export type QueryPendingPointsRedeemTransactionsArgs = {
	pagination: PaginatedInput
}

export type QueryPointsRedeemItemByIdArgs = {
	pointsRedeemItemId: Scalars["String"]
}

export type QueryResourceByIdArgs = {
	resourceId: Scalars["String"]
}

export type QueryRestrictedZoneByIdArgs = {
	restrictedZoneId: Scalars["String"]
}

export type QueryRewardCategoryByIdArgs = {
	rewardCategoryId: Scalars["String"]
}

export type QueryRewardItemByIdArgs = {
	rewardItemId: Scalars["String"]
}

export type QueryRewardsOfStaffArgs = {
	staffId: Scalars["String"]
}

export type QueryRewardsOfStudentArgs = {
	studentId: Scalars["String"]
}

export type QuerySearchBusRouteArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchCircularArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchClassesArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchDepartmentsArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchGalleryArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchHolidayArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchHousesArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchPenaltyCategoriesForStaffArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchPenaltyCategoriesForStudentsArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchPenaltyItemsArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchPointsRedeemItemsForStaffArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchPointsRedeemItemsForStudentsArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchResourceArgs = {
	classId?: InputMaybe<Scalars["String"]>
	classSectionId?: InputMaybe<Scalars["String"]>
	keyword: Scalars["String"]
	pagination: PaginatedInput
	subjectId?: InputMaybe<Scalars["String"]>
	topicId?: InputMaybe<Scalars["String"]>
}

export type QuerySearchRestrictedZonesArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchRewardCategoriesForStaffArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchRewardCategoriesForStudentsArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchRewardItemsArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchShowcaseArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchStaffsArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchStudentsArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchSubjectArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchTopicArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchTrainersArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchTrendingSkillArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
	topicId?: InputMaybe<Scalars["String"]>
}

export type QuerySearchWorkshopsArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryShowcaseByIdArgs = {
	showcaseId: Scalars["String"]
}

export type QueryStaffByIdArgs = {
	staffId: Scalars["String"]
}

export type QueryStaffsWithoutPaginationArgs = {
	departmentId?: InputMaybe<Scalars["String"]>
	departmentType?: InputMaybe<DepartmentTypes>
}

export type QueryStockReadersArgs = {
	pagination: PaginatedInput
}

export type QueryStockTagsArgs = {
	pagination: PaginatedInput
}

export type QueryStudentByIdArgs = {
	studentId: Scalars["String"]
}

export type QueryStudentByParentTagHexIdArgs = {
	tagHexId: Scalars["String"]
}

export type QueryStudentsArgs = {
	classId?: InputMaybe<Scalars["String"]>
	classSectionId?: InputMaybe<Scalars["String"]>
}

export type QuerySubjectByIdArgs = {
	subjectId: Scalars["String"]
}

export type QueryTagByHexIdArgs = {
	tagHexId: Scalars["String"]
}

export type QueryTodayAttendanceOfStaffArgs = {
	staffId: Scalars["String"]
}

export type QueryTodayAttendanceOfStudentArgs = {
	studentId: Scalars["String"]
}

export type QueryTodayBusAttendanceOfStaffArgs = {
	staffId: Scalars["String"]
}

export type QueryTodayBusAttendanceOfStudentArgs = {
	studentId: Scalars["String"]
}

export type QueryTopicByIdArgs = {
	topicId: Scalars["String"]
}

export type QueryTrainerByIdArgs = {
	trainerId: Scalars["String"]
}

export type QueryTrendingSkillByIdArgs = {
	trendingSkillId: Scalars["String"]
}

export type QueryUnauthorizedClassesArgs = {
	restrictedZoneId: Scalars["String"]
}

export type QueryUnauthorizedDepartmentsArgs = {
	restrictedZoneId: Scalars["String"]
}

export type QueryWhitelistedStaffsWithoutPaginationArgs = {
	departmentId?: InputMaybe<Scalars["String"]>
	departmentType?: InputMaybe<DepartmentTypes>
	keyword?: InputMaybe<Scalars["String"]>
}

export type QueryWhitelistedStudentsArgs = {
	classId?: InputMaybe<Scalars["String"]>
	classSectionId?: InputMaybe<Scalars["String"]>
	keyword?: InputMaybe<Scalars["String"]>
}

export type QueryWorkshopByIdArgs = {
	workshopId: Scalars["String"]
}

export type QueryWorkshopSessionByIdArgs = {
	workshopSessionId: Scalars["String"]
}

export type Rating = {
	__typename?: "Rating"
	_id: Scalars["String"]
	author?: Maybe<Author>
	comment?: Maybe<Scalars["String"]>
	createdAt: Scalars["DateTime"]
	rating: Scalars["Float"]
	updatedAt: Scalars["DateTime"]
}

export type Reader = {
	__typename?: "Reader"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	isActive?: Maybe<Scalars["Boolean"]>
	isRestrictedZone?: Maybe<Scalars["Boolean"]>
	label?: Maybe<Scalars["String"]>
	lastConnectedAt?: Maybe<Scalars["DateTime"]>
	lastConnectionEndpoint?: Maybe<Scalars["String"]>
	lastHeartbeatAt?: Maybe<Scalars["DateTime"]>
	readerDecimalId: Scalars["String"]
	readerHexId: Scalars["String"]
	restrictedZoneId?: Maybe<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
}

export type Recommendation = {
	__typename?: "Recommendation"
	_id: Scalars["String"]
	author?: Maybe<Author>
	comment: Scalars["String"]
	createdAt: Scalars["DateTime"]
	updatedAt: Scalars["DateTime"]
}

export type Resource = {
	__typename?: "Resource"
	_id: Scalars["String"]
	attachments: MediaAttachments
	author: Author
	classId: Scalars["String"]
	classSectionId?: Maybe<Scalars["String"]>
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	name: Scalars["String"]
	subTopic?: Maybe<Scalars["String"]>
	subjectId: Scalars["String"]
	targets: Array<UserTargets>
	topicId: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type ResourceInput = {
	attachments: MediaAttachmentsInput
	classId: Scalars["String"]
	classSectionId?: InputMaybe<Scalars["String"]>
	description: Scalars["String"]
	name: Scalars["String"]
	subTopic?: InputMaybe<Scalars["String"]>
	subjectId: Scalars["String"]
	targets: Array<UserTargets>
	topicId: Scalars["String"]
}

export type ResourceWithFieldErrorsResponse = {
	__typename?: "ResourceWithFieldErrorsResponse"
	errors?: Maybe<Array<FieldError>>
	resource?: Maybe<ExtendedResource>
}

export type RestrictedEntrySubscriptionInput = {
	receivedEntries?: InputMaybe<Array<Entry>>
}

export type RestrictedZone = {
	__typename?: "RestrictedZone"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	readerIds: Array<Scalars["String"]>
	unauthorizedClasses: Array<UnauthorizedClass>
	unauthorizedDepartmentIds: Array<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
}

export type RestrictedZoneInput = {
	name: Scalars["String"]
}

export type RewardCategory = {
	__typename?: "RewardCategory"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	name: Scalars["String"]
	target: UserTargets
	updatedAt: Scalars["DateTime"]
}

export type RewardCategoryInput = {
	description: Scalars["String"]
	name: Scalars["String"]
	target: UserTargets
}

export type RewardCategoryWithFieldErrorsResponse = {
	__typename?: "RewardCategoryWithFieldErrorsResponse"
	errors?: Maybe<Array<FieldError>>
	rewardCategory?: Maybe<RewardCategory>
}

export type RewardItem = {
	__typename?: "RewardItem"
	_id: Scalars["String"]
	categoryId: Scalars["String"]
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	name: Scalars["String"]
	points: Scalars["Float"]
	updatedAt: Scalars["DateTime"]
}

export type RewardItemInput = {
	categoryId: Scalars["String"]
	description: Scalars["String"]
	name: Scalars["String"]
	points: Scalars["Float"]
}

export type RewardItemWithFieldErrorsResponse = {
	__typename?: "RewardItemWithFieldErrorsResponse"
	errors?: Maybe<Array<FieldError>>
	rewardItem?: Maybe<RewardItem>
}

export type School = {
	__typename?: "School"
	_id: Scalars["String"]
	affiliationCode: Scalars["String"]
	cover?: Maybe<EmbeddedPicture>
	createdAt: Scalars["DateTime"]
	email?: Maybe<Scalars["String"]>
	location: Location
	name: Scalars["String"]
	phone: Scalars["String"]
	picture?: Maybe<EmbeddedPicture>
	schoolCode: Scalars["String"]
	updatedAt: Scalars["DateTime"]
	username: Scalars["String"]
}

export type Showcase = {
	__typename?: "Showcase"
	_id: Scalars["String"]
	attachments?: Maybe<MediaAttachments>
	author: Author
	authors?: Maybe<Array<ShowcaseAuthor>>
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	qrCode?: Maybe<EmbeddedPicture>
	title: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type ShowcaseAuthor = {
	__typename?: "ShowcaseAuthor"
	_id: Scalars["String"]
	name: Scalars["String"]
	picture: EmbeddedPicture
}

export type ShowcaseAuthorInput = {
	name: Scalars["String"]
	picture: PictureInput
}

export type ShowcaseInput = {
	attachments?: InputMaybe<MediaAttachmentsInput>
	description: Scalars["String"]
	title: Scalars["String"]
}

export type ShowcaseWithFieldErrorsResponse = {
	__typename?: "ShowcaseWithFieldErrorsResponse"
	errors?: Maybe<Array<FieldError>>
	showcase?: Maybe<ExtendedShowcase>
}

export type Staff = {
	__typename?: "Staff"
	_id: Scalars["String"]
	address?: Maybe<Scalars["String"]>
	bloodGroup?: Maybe<BloodGroups>
	createdAt: Scalars["DateTime"]
	dateOfBirth?: Maybe<Scalars["DateTime"]>
	departmentId: Scalars["String"]
	designation: Scalars["String"]
	email?: Maybe<Scalars["String"]>
	gender?: Maybe<Genders>
	isWhitelisted?: Maybe<Scalars["Boolean"]>
	name: Scalars["String"]
	pastRoles?: Maybe<Array<StaffRole>>
	phone?: Maybe<Scalars["String"]>
	picture?: Maybe<EmbeddedPicture>
	pointsBalance?: Maybe<Scalars["Float"]>
	roles?: Maybe<Array<StaffRole>>
	staffId: Scalars["String"]
	subjectIds?: Maybe<Array<Scalars["String"]>>
	tagId?: Maybe<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
	username: Scalars["String"]
}

export type StaffAttendance = {
	__typename?: "StaffAttendance"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	inAt: Scalars["DateTime"]
	inReaderId?: Maybe<Scalars["String"]>
	isFinal?: Maybe<Scalars["Boolean"]>
	isInManual?: Maybe<Scalars["Boolean"]>
	isInRecorded?: Maybe<Scalars["Boolean"]>
	isLate?: Maybe<Scalars["Boolean"]>
	isOutManual?: Maybe<Scalars["Boolean"]>
	isOutRecorded?: Maybe<Scalars["Boolean"]>
	lateByMinutes?: Maybe<Scalars["Float"]>
	outAt: Scalars["DateTime"]
	outReaderId?: Maybe<Scalars["String"]>
	rssi: Scalars["Float"]
	staffId: Scalars["String"]
	temperature: Scalars["Float"]
	timing: StaffAttendanceTiming
	updatedAt: Scalars["DateTime"]
}

export type StaffAttendanceReportResponse = {
	__typename?: "StaffAttendanceReportResponse"
	attendances: Array<LabelledStaffAttendances>
	labels: Array<Scalars["String"]>
}

export type StaffAttendanceStatsResponse = {
	__typename?: "StaffAttendanceStatsResponse"
	absent: Array<Staff>
	present: Array<ExtendedStaffAttendance>
	total: Scalars["Float"]
}

export type StaffAttendanceTiming = {
	__typename?: "StaffAttendanceTiming"
	inAt: Scalars["String"]
	inStartBeforeMinutes: Scalars["Float"]
	outAt: Scalars["String"]
	outStartBeforeMinutes: Scalars["Float"]
}

export type StaffAttendanceTimingInput = {
	inAt: Scalars["String"]
	inStartBeforeMinutes: Scalars["Float"]
	outAt: Scalars["String"]
	outStartBeforeMinutes: Scalars["Float"]
}

export type StaffBusAttendance = {
	__typename?: "StaffBusAttendance"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	inAt: Scalars["DateTime"]
	isInRecorded?: Maybe<Scalars["Boolean"]>
	isOutRecorded?: Maybe<Scalars["Boolean"]>
	outAt: Scalars["DateTime"]
	staffId: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type StaffInput = {
	departmentId: Scalars["String"]
	designation: Scalars["String"]
	email?: InputMaybe<Scalars["String"]>
	isWhitelisted?: InputMaybe<Scalars["Boolean"]>
	name: Scalars["String"]
	phone: Scalars["String"]
	staffId: Scalars["String"]
	subjectIds?: InputMaybe<Array<Scalars["String"]>>
	tagHexOrDecId: Scalars["String"]
}

export type StaffReportInput = {
	sinceDate: Scalars["DateTime"]
	staffIds: Array<Scalars["String"]>
	untilDate: Scalars["DateTime"]
}

export type StaffRole = {
	__typename?: "StaffRole"
	assignedAt: Scalars["DateTime"]
	classId?: Maybe<Scalars["String"]>
	classSectionId?: Maybe<Scalars["String"]>
	removedAt?: Maybe<Scalars["DateTime"]>
	role: StaffRoles
}

export enum StaffRoles {
	ClassTeacher = "ClassTeacher",
}

export type StaffWithFieldErrorsResponse = {
	__typename?: "StaffWithFieldErrorsResponse"
	errors?: Maybe<Array<FieldError>>
	staff?: Maybe<ExtendedStaff>
}

export type StatisticsResponse = {
	__typename?: "StatisticsResponse"
	classes?: Maybe<Scalars["Float"]>
	nonTeachingStaff?: Maybe<Scalars["Float"]>
	students?: Maybe<Scalars["Float"]>
	subjects?: Maybe<Scalars["Float"]>
	teachingStaff?: Maybe<Scalars["Float"]>
}

export type StockReader = {
	__typename?: "StockReader"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	isRegistered?: Maybe<Scalars["Boolean"]>
	readerDecimalId: Scalars["String"]
	readerHexId: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type StockTag = {
	__typename?: "StockTag"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	expiryAt: Scalars["DateTime"]
	isRegistered?: Maybe<Scalars["Boolean"]>
	tagDecimalId: Scalars["String"]
	tagHexId: Scalars["String"]
	type: TagTypes
	updatedAt: Scalars["DateTime"]
}

export type Student = {
	__typename?: "Student"
	_id: Scalars["String"]
	address?: Maybe<Scalars["String"]>
	admissionDate?: Maybe<Scalars["DateTime"]>
	admissionId: Scalars["String"]
	admissionType?: Maybe<AdmissionTypes>
	bloodGroup?: Maybe<BloodGroups>
	busRouteId?: Maybe<Scalars["String"]>
	classId: Scalars["String"]
	classSectionId: Scalars["String"]
	createdAt: Scalars["DateTime"]
	dateOfBirth?: Maybe<Scalars["DateTime"]>
	email?: Maybe<Scalars["String"]>
	father?: Maybe<Parent>
	gender?: Maybe<Genders>
	guardian1?: Maybe<Parent>
	guardian2?: Maybe<Parent>
	houseId?: Maybe<Scalars["String"]>
	isALS?: Maybe<Scalars["Boolean"]>
	isELS?: Maybe<Scalars["Boolean"]>
	isWhitelisted?: Maybe<Scalars["Boolean"]>
	mother?: Maybe<Parent>
	name: Scalars["String"]
	nationality?: Maybe<Scalars["String"]>
	phone: Scalars["String"]
	picture?: Maybe<EmbeddedPicture>
	pointsBalance?: Maybe<Scalars["Float"]>
	status?: Maybe<Scalars["String"]>
	tagId?: Maybe<Scalars["String"]>
	transportMode?: Maybe<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
	username: Scalars["String"]
}

export type StudentAttendance = {
	__typename?: "StudentAttendance"
	_id: Scalars["String"]
	attendanceMarkedBy?: Maybe<Author>
	classId: Scalars["String"]
	classSectionId: Scalars["String"]
	createdAt: Scalars["DateTime"]
	inAt: Scalars["DateTime"]
	inReaderId?: Maybe<Scalars["String"]>
	isAttendanceMarkedByClassTeacher?: Maybe<Scalars["Boolean"]>
	isFinal?: Maybe<Scalars["Boolean"]>
	isInManual?: Maybe<Scalars["Boolean"]>
	isInRecorded?: Maybe<Scalars["Boolean"]>
	isLate?: Maybe<Scalars["Boolean"]>
	isOutManual?: Maybe<Scalars["Boolean"]>
	isOutRecorded?: Maybe<Scalars["Boolean"]>
	lateByMinutes?: Maybe<Scalars["Float"]>
	outAt: Scalars["DateTime"]
	outReaderId?: Maybe<Scalars["String"]>
	rssi: Scalars["Float"]
	studentId: Scalars["String"]
	temperature: Scalars["Float"]
	timing: AttendanceTiming
	updatedAt: Scalars["DateTime"]
}

export type StudentAttendanceReportResponse = {
	__typename?: "StudentAttendanceReportResponse"
	attendances: Array<LabelledStudentAttendances>
	labels: Array<Scalars["String"]>
}

export type StudentBusAttendance = {
	__typename?: "StudentBusAttendance"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	homeDropAt?: Maybe<Scalars["DateTime"]>
	homePickupAt?: Maybe<Scalars["DateTime"]>
	schoolDropAt?: Maybe<Scalars["DateTime"]>
	schoolPickupAt?: Maybe<Scalars["DateTime"]>
	studentId: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type StudentInput = {
	admissionDate?: InputMaybe<Scalars["DateTime"]>
	admissionId: Scalars["String"]
	admissionType?: InputMaybe<AdmissionTypes>
	busRouteId?: InputMaybe<Scalars["String"]>
	classId: Scalars["String"]
	classSectionId: Scalars["String"]
	email?: InputMaybe<Scalars["String"]>
	houseId?: InputMaybe<Scalars["String"]>
	isALS?: InputMaybe<Scalars["Boolean"]>
	isELS?: InputMaybe<Scalars["Boolean"]>
	isWhitelisted?: InputMaybe<Scalars["Boolean"]>
	name: Scalars["String"]
	nationality?: InputMaybe<Scalars["String"]>
	phone?: InputMaybe<Scalars["String"]>
	tagHexOrDecId?: InputMaybe<Scalars["String"]>
	transportMode?: InputMaybe<Scalars["String"]>
}

export type StudentTelegramMessageReportResponse = {
	__typename?: "StudentTelegramMessageReportResponse"
	labels: Array<Scalars["String"]>
	messages: Array<LabelledStudentTelegramMessages>
}

export type StudentWithFieldErrorsResponse = {
	__typename?: "StudentWithFieldErrorsResponse"
	errors?: Maybe<Array<FieldError>>
	student?: Maybe<ExtendedStudent>
}

export type StudentsReportInput = {
	sinceDate: Scalars["DateTime"]
	studentIds: Array<Scalars["String"]>
	untilDate: Scalars["DateTime"]
}

export type Subject = {
	__typename?: "Subject"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type SubjectWithFieldErrorsResponse = {
	__typename?: "SubjectWithFieldErrorsResponse"
	errors?: Maybe<Array<FieldError>>
	subject?: Maybe<Subject>
}

export type Subscription = {
	__typename?: "Subscription"
	restrictedEntry: ExtendedRestrictedEntryRecord
	todayAttendanceUpdateOfStaff: ExtendedStaffAttendance
	todayAttendanceUpdateOfStudent: ExtendedStudentAttendance
	todayBusAttendanceUpdateOfStaff: StaffBusAttendance
	todayBusAttendanceUpdateOfStudent: StudentBusAttendance
}

export type SubscriptionRestrictedEntryArgs = {
	input: RestrictedEntrySubscriptionInput
}

export type SubscriptionTodayAttendanceUpdateOfStaffArgs = {
	staffId: Scalars["String"]
}

export type SubscriptionTodayAttendanceUpdateOfStudentArgs = {
	studentId: Scalars["String"]
}

export type SubscriptionTodayBusAttendanceUpdateOfStaffArgs = {
	staffId: Scalars["String"]
}

export type SubscriptionTodayBusAttendanceUpdateOfStudentArgs = {
	studentId: Scalars["String"]
}

export type Tag = {
	__typename?: "Tag"
	_id: Scalars["String"]
	author: Author
	createdAt: Scalars["DateTime"]
	expiryAt: Scalars["DateTime"]
	tagDecimalId: Scalars["String"]
	tagHexId: Scalars["String"]
	type: TagTypes
	updatedAt: Scalars["DateTime"]
}

export enum TagTypes {
	Band = "Band",
	Card = "Card",
}

export type TelegramMessage = {
	__typename?: "TelegramMessage"
	_id: Scalars["String"]
	chatId: Scalars["String"]
	createdAt: Scalars["DateTime"]
	parseMode?: Maybe<Scalars["String"]>
	retries?: Maybe<Scalars["Float"]>
	staffId?: Maybe<Scalars["String"]>
	studentId?: Maybe<Scalars["String"]>
	texts: Array<Scalars["String"]>
	type: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type Topic = {
	__typename?: "Topic"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	subTopics?: Maybe<Array<Scalars["String"]>>
	updatedAt: Scalars["DateTime"]
}

export type TopicWithFieldErrorsResponse = {
	__typename?: "TopicWithFieldErrorsResponse"
	errors?: Maybe<Array<FieldError>>
	topic?: Maybe<Topic>
}

export type Trainer = {
	__typename?: "Trainer"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	name: Scalars["String"]
	picture?: Maybe<EmbeddedPicture>
	ratings?: Maybe<Array<Rating>>
	updatedAt: Scalars["DateTime"]
}

export type TrainerInput = {
	description: Scalars["String"]
	name: Scalars["String"]
}

export type TrainerWithFieldErrorsResponse = {
	__typename?: "TrainerWithFieldErrorsResponse"
	errors?: Maybe<Array<FieldError>>
	trainer?: Maybe<Trainer>
}

export type TrendingSkillInput = {
	description: Scalars["String"]
	links: Scalars["String"]
	maxAge?: InputMaybe<Scalars["Float"]>
	minAge?: InputMaybe<Scalars["Float"]>
	name: Scalars["String"]
	topicId: Scalars["String"]
}

export type TrendingSkillWithFieldErrorsResponse = {
	__typename?: "TrendingSkillWithFieldErrorsResponse"
	errors?: Maybe<Array<FieldError>>
	trendingSkill?: Maybe<ExtendedTrendingSkill>
}

export type UnauthorizedClass = {
	__typename?: "UnauthorizedClass"
	classId: Scalars["String"]
	classSectionId: Scalars["String"]
}

export type UploadSignatureResponse = {
	__typename?: "UploadSignatureResponse"
	key: Scalars["String"]
	signedUrl: Scalars["String"]
}

export enum UserTargets {
	Staffs = "Staffs",
	Students = "Students",
}

export type VideoInput = {
	key: Scalars["String"]
	url: Scalars["String"]
}

export type VideoVariants = {
	__typename?: "VideoVariants"
	large?: Maybe<Scalars["String"]>
	small?: Maybe<Scalars["String"]>
}

export type Workshop = {
	__typename?: "Workshop"
	_id: Scalars["String"]
	benefit: Scalars["String"]
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	enrolledUsers?: Maybe<Array<Author>>
	isActive: Scalars["Boolean"]
	isLive: Scalars["Boolean"]
	isPaid: Scalars["Boolean"]
	isPublic: Scalars["Boolean"]
	priceInINR?: Maybe<Scalars["Float"]>
	ratings?: Maybe<Array<Rating>>
	recommendations?: Maybe<Array<Recommendation>>
	thumbnail?: Maybe<EmbeddedPicture>
	title: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type WorkshopInput = {
	benefit: Scalars["String"]
	description: Scalars["String"]
	isLive: Scalars["Boolean"]
	isPaid: Scalars["Boolean"]
	isPublic: Scalars["Boolean"]
	priceInINR?: InputMaybe<Scalars["Float"]>
	title: Scalars["String"]
}

export type WorkshopSession = {
	__typename?: "WorkshopSession"
	_id: Scalars["String"]
	attachments?: Maybe<MediaAttachments>
	benefit: Scalars["String"]
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	isActive: Scalars["Boolean"]
	liveSessionUrl?: Maybe<Scalars["String"]>
	ratings?: Maybe<Array<Rating>>
	thumbnail?: Maybe<EmbeddedPicture>
	title: Scalars["String"]
	trainersIds?: Maybe<Array<Scalars["String"]>>
	updatedAt: Scalars["DateTime"]
	video?: Maybe<EmbeddedVideo>
	workshopId: Scalars["String"]
}

export type WorkshopSessionInput = {
	benefit: Scalars["String"]
	description: Scalars["String"]
	title: Scalars["String"]
}

export type WorkshopSessionWithFieldErrorsResponse = {
	__typename?: "WorkshopSessionWithFieldErrorsResponse"
	errors?: Maybe<Array<FieldError>>
	session?: Maybe<ExtendedWorkshopSession>
}

export type WorkshopWithFieldErrorsResponse = {
	__typename?: "WorkshopWithFieldErrorsResponse"
	errors?: Maybe<Array<FieldError>>
	workshop?: Maybe<Workshop>
}

export type EmbeddedPdfFragment = { __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }

export type EmbeddedPictureFragment = {
	__typename?: "EmbeddedPicture"
	key: string
	url: string
	createdAt: any
	updatedAt: any
	variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
}

export type EmbeddedVideoFragment = {
	__typename?: "EmbeddedVideo"
	key: string
	url: string
	thumbnail?: string | null
	createdAt: any
	updatedAt: any
	variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
}

export type ExtendedAuthorFragment = {
	__typename?: "ExtendedAuthor"
	authorType: AuthorTypes
	studentId?: string | null
	staffId?: string | null
	schoolId?: string | null
	student?: {
		__typename?: "Student"
		_id: string
		name: string
		username: string
		email?: string | null
		phone: string
		admissionId: string
		admissionDate?: any | null
		admissionType?: AdmissionTypes | null
		classId: string
		classSectionId: string
		tagId?: string | null
		houseId?: string | null
		isALS?: boolean | null
		isELS?: boolean | null
		status?: string | null
		nationality?: string | null
		gender?: Genders | null
		dateOfBirth?: any | null
		address?: string | null
		bloodGroup?: BloodGroups | null
		pointsBalance?: number | null
		isWhitelisted?: boolean | null
		transportMode?: string | null
		busRouteId?: string | null
		createdAt: any
		updatedAt: any
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
	} | null
	staff?: {
		__typename?: "Staff"
		_id: string
		name: string
		email?: string | null
		phone?: string | null
		staffId: string
		designation: string
		username: string
		departmentId: string
		tagId?: string | null
		gender?: Genders | null
		dateOfBirth?: any | null
		address?: string | null
		bloodGroup?: BloodGroups | null
		isWhitelisted?: boolean | null
		pointsBalance?: number | null
		subjectIds?: Array<string> | null
		createdAt: any
		updatedAt: any
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
	} | null
	school?: {
		__typename?: "School"
		_id: string
		name: string
		schoolCode: string
		affiliationCode: string
		email?: string | null
		phone: string
		username: string
		createdAt: any
		updatedAt: any
		location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
		cover?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
	} | null
}

export type ExtendedShowcaseFragment = {
	__typename?: "ExtendedShowcase"
	_id: string
	title: string
	description: string
	createdAt: any
	updatedAt: any
	author: {
		__typename?: "ExtendedAuthor"
		authorType: AuthorTypes
		studentId?: string | null
		staffId?: string | null
		schoolId?: string | null
		student?: {
			__typename?: "Student"
			_id: string
			name: string
			username: string
			email?: string | null
			phone: string
			admissionId: string
			admissionDate?: any | null
			admissionType?: AdmissionTypes | null
			classId: string
			classSectionId: string
			tagId?: string | null
			houseId?: string | null
			isALS?: boolean | null
			isELS?: boolean | null
			status?: string | null
			nationality?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			pointsBalance?: number | null
			isWhitelisted?: boolean | null
			transportMode?: string | null
			busRouteId?: string | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		staff?: {
			__typename?: "Staff"
			_id: string
			name: string
			email?: string | null
			phone?: string | null
			staffId: string
			designation: string
			username: string
			departmentId: string
			tagId?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			isWhitelisted?: boolean | null
			pointsBalance?: number | null
			subjectIds?: Array<string> | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		school?: {
			__typename?: "School"
			_id: string
			name: string
			schoolCode: string
			affiliationCode: string
			email?: string | null
			phone: string
			username: string
			createdAt: any
			updatedAt: any
			location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			cover?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
	}
	attachments?: {
		__typename?: "MediaAttachments"
		pictures?: Array<{
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		}> | null
		videos?: Array<{
			__typename?: "EmbeddedVideo"
			key: string
			url: string
			thumbnail?: string | null
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
		}> | null
		pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
	} | null
	authors?: Array<{
		__typename?: "ShowcaseAuthor"
		_id: string
		name: string
		picture: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		}
	}> | null
}

export type MediaAttachmentsFragment = {
	__typename?: "MediaAttachments"
	pictures?: Array<{
		__typename?: "EmbeddedPicture"
		key: string
		url: string
		createdAt: any
		updatedAt: any
		variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
	}> | null
	videos?: Array<{
		__typename?: "EmbeddedVideo"
		key: string
		url: string
		thumbnail?: string | null
		createdAt: any
		updatedAt: any
		variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
	}> | null
	pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
}

export type SchoolFragment = {
	__typename?: "School"
	_id: string
	name: string
	schoolCode: string
	affiliationCode: string
	email?: string | null
	phone: string
	username: string
	createdAt: any
	updatedAt: any
	location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
	picture?: {
		__typename?: "EmbeddedPicture"
		key: string
		url: string
		createdAt: any
		updatedAt: any
		variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
	} | null
	cover?: {
		__typename?: "EmbeddedPicture"
		key: string
		url: string
		createdAt: any
		updatedAt: any
		variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
	} | null
}

export type ShowcaseFragment = {
	__typename?: "Showcase"
	_id: string
	title: string
	description: string
	createdAt: any
	updatedAt: any
	attachments?: {
		__typename?: "MediaAttachments"
		pictures?: Array<{
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		}> | null
		videos?: Array<{
			__typename?: "EmbeddedVideo"
			key: string
			url: string
			thumbnail?: string | null
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
		}> | null
		pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
	} | null
	authors?: Array<{
		__typename?: "ShowcaseAuthor"
		_id: string
		name: string
		picture: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		}
	}> | null
}

export type StaffFragment = {
	__typename?: "Staff"
	_id: string
	name: string
	email?: string | null
	phone?: string | null
	staffId: string
	designation: string
	username: string
	departmentId: string
	tagId?: string | null
	gender?: Genders | null
	dateOfBirth?: any | null
	address?: string | null
	bloodGroup?: BloodGroups | null
	isWhitelisted?: boolean | null
	pointsBalance?: number | null
	subjectIds?: Array<string> | null
	createdAt: any
	updatedAt: any
	picture?: {
		__typename?: "EmbeddedPicture"
		key: string
		url: string
		createdAt: any
		updatedAt: any
		variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
	} | null
}

export type StudentFragment = {
	__typename?: "Student"
	_id: string
	name: string
	username: string
	email?: string | null
	phone: string
	admissionId: string
	admissionDate?: any | null
	admissionType?: AdmissionTypes | null
	classId: string
	classSectionId: string
	tagId?: string | null
	houseId?: string | null
	isALS?: boolean | null
	isELS?: boolean | null
	status?: string | null
	nationality?: string | null
	gender?: Genders | null
	dateOfBirth?: any | null
	address?: string | null
	bloodGroup?: BloodGroups | null
	pointsBalance?: number | null
	isWhitelisted?: boolean | null
	transportMode?: string | null
	busRouteId?: string | null
	createdAt: any
	updatedAt: any
	picture?: {
		__typename?: "EmbeddedPicture"
		key: string
		url: string
		createdAt: any
		updatedAt: any
		variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
	} | null
}

export type AllShowcasesQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllShowcasesQuery = {
	__typename?: "Query"
	allShowcases: {
		__typename?: "PaginatedShowcasesResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		totalPages: number
		total: number
		currentPage: number
		showcases?: Array<{
			__typename?: "ExtendedShowcase"
			_id: string
			title: string
			description: string
			createdAt: any
			updatedAt: any
			author: {
				__typename?: "ExtendedAuthor"
				authorType: AuthorTypes
				studentId?: string | null
				staffId?: string | null
				schoolId?: string | null
				student?: {
					__typename?: "Student"
					_id: string
					name: string
					username: string
					email?: string | null
					phone: string
					admissionId: string
					admissionDate?: any | null
					admissionType?: AdmissionTypes | null
					classId: string
					classSectionId: string
					tagId?: string | null
					houseId?: string | null
					isALS?: boolean | null
					isELS?: boolean | null
					status?: string | null
					nationality?: string | null
					gender?: Genders | null
					dateOfBirth?: any | null
					address?: string | null
					bloodGroup?: BloodGroups | null
					pointsBalance?: number | null
					isWhitelisted?: boolean | null
					transportMode?: string | null
					busRouteId?: string | null
					createdAt: any
					updatedAt: any
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				staff?: {
					__typename?: "Staff"
					_id: string
					name: string
					email?: string | null
					phone?: string | null
					staffId: string
					designation: string
					username: string
					departmentId: string
					tagId?: string | null
					gender?: Genders | null
					dateOfBirth?: any | null
					address?: string | null
					bloodGroup?: BloodGroups | null
					isWhitelisted?: boolean | null
					pointsBalance?: number | null
					subjectIds?: Array<string> | null
					createdAt: any
					updatedAt: any
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				school?: {
					__typename?: "School"
					_id: string
					name: string
					schoolCode: string
					affiliationCode: string
					email?: string | null
					phone: string
					username: string
					createdAt: any
					updatedAt: any
					location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
					cover?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			}
			attachments?: {
				__typename?: "MediaAttachments"
				pictures?: Array<{
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				}> | null
				videos?: Array<{
					__typename?: "EmbeddedVideo"
					key: string
					url: string
					thumbnail?: string | null
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
				}> | null
				pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
			} | null
			authors?: Array<{
				__typename?: "ShowcaseAuthor"
				_id: string
				name: string
				picture: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				}
			}> | null
		}> | null
	}
}

export type SearchShowcaseQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type SearchShowcaseQuery = {
	__typename?: "Query"
	searchShowcase: {
		__typename?: "PaginatedShowcasesResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		totalPages: number
		total: number
		currentPage: number
		showcases?: Array<{
			__typename?: "ExtendedShowcase"
			_id: string
			title: string
			description: string
			createdAt: any
			updatedAt: any
			author: {
				__typename?: "ExtendedAuthor"
				authorType: AuthorTypes
				studentId?: string | null
				staffId?: string | null
				schoolId?: string | null
				student?: {
					__typename?: "Student"
					_id: string
					name: string
					username: string
					email?: string | null
					phone: string
					admissionId: string
					admissionDate?: any | null
					admissionType?: AdmissionTypes | null
					classId: string
					classSectionId: string
					tagId?: string | null
					houseId?: string | null
					isALS?: boolean | null
					isELS?: boolean | null
					status?: string | null
					nationality?: string | null
					gender?: Genders | null
					dateOfBirth?: any | null
					address?: string | null
					bloodGroup?: BloodGroups | null
					pointsBalance?: number | null
					isWhitelisted?: boolean | null
					transportMode?: string | null
					busRouteId?: string | null
					createdAt: any
					updatedAt: any
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				staff?: {
					__typename?: "Staff"
					_id: string
					name: string
					email?: string | null
					phone?: string | null
					staffId: string
					designation: string
					username: string
					departmentId: string
					tagId?: string | null
					gender?: Genders | null
					dateOfBirth?: any | null
					address?: string | null
					bloodGroup?: BloodGroups | null
					isWhitelisted?: boolean | null
					pointsBalance?: number | null
					subjectIds?: Array<string> | null
					createdAt: any
					updatedAt: any
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				school?: {
					__typename?: "School"
					_id: string
					name: string
					schoolCode: string
					affiliationCode: string
					email?: string | null
					phone: string
					username: string
					createdAt: any
					updatedAt: any
					location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
					cover?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			}
			attachments?: {
				__typename?: "MediaAttachments"
				pictures?: Array<{
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				}> | null
				videos?: Array<{
					__typename?: "EmbeddedVideo"
					key: string
					url: string
					thumbnail?: string | null
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
				}> | null
				pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
			} | null
			authors?: Array<{
				__typename?: "ShowcaseAuthor"
				_id: string
				name: string
				picture: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				}
			}> | null
		}> | null
	}
}

export type ShowcaseByIdQueryVariables = Exact<{
	showcaseId: Scalars["String"]
}>

export type ShowcaseByIdQuery = {
	__typename?: "Query"
	showcaseById: {
		__typename?: "ExtendedShowcase"
		_id: string
		title: string
		description: string
		createdAt: any
		updatedAt: any
		author: {
			__typename?: "ExtendedAuthor"
			authorType: AuthorTypes
			studentId?: string | null
			staffId?: string | null
			schoolId?: string | null
			student?: {
				__typename?: "Student"
				_id: string
				name: string
				username: string
				email?: string | null
				phone: string
				admissionId: string
				admissionDate?: any | null
				admissionType?: AdmissionTypes | null
				classId: string
				classSectionId: string
				tagId?: string | null
				houseId?: string | null
				isALS?: boolean | null
				isELS?: boolean | null
				status?: string | null
				nationality?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				pointsBalance?: number | null
				isWhitelisted?: boolean | null
				transportMode?: string | null
				busRouteId?: string | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			staff?: {
				__typename?: "Staff"
				_id: string
				name: string
				email?: string | null
				phone?: string | null
				staffId: string
				designation: string
				username: string
				departmentId: string
				tagId?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				isWhitelisted?: boolean | null
				pointsBalance?: number | null
				subjectIds?: Array<string> | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			school?: {
				__typename?: "School"
				_id: string
				name: string
				schoolCode: string
				affiliationCode: string
				email?: string | null
				phone: string
				username: string
				createdAt: any
				updatedAt: any
				location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				cover?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		}
		attachments?: {
			__typename?: "MediaAttachments"
			pictures?: Array<{
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			}> | null
			videos?: Array<{
				__typename?: "EmbeddedVideo"
				key: string
				url: string
				thumbnail?: string | null
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
			}> | null
			pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
		} | null
		authors?: Array<{
			__typename?: "ShowcaseAuthor"
			_id: string
			name: string
			picture: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			}
		}> | null
	}
}

export const EmbeddedPictureFragmentDoc = gql`
	fragment EmbeddedPicture on EmbeddedPicture {
		key
		url
		variants {
			thumbnail
			small
			large
		}
		createdAt
		updatedAt
	}
`
export const StudentFragmentDoc = gql`
	fragment Student on Student {
		_id
		name
		username
		email
		phone
		admissionId
		admissionDate
		admissionType
		picture {
			...EmbeddedPicture
		}
		classId
		classSectionId
		tagId
		houseId
		isALS
		isELS
		status
		nationality
		gender
		dateOfBirth
		address
		bloodGroup
		pointsBalance
		isWhitelisted
		transportMode
		busRouteId
		createdAt
		updatedAt
	}
	${EmbeddedPictureFragmentDoc}
`
export const StaffFragmentDoc = gql`
	fragment Staff on Staff {
		_id
		name
		email
		phone
		picture {
			...EmbeddedPicture
		}
		staffId
		designation
		username
		departmentId
		tagId
		gender
		dateOfBirth
		address
		bloodGroup
		isWhitelisted
		pointsBalance
		subjectIds
		createdAt
		updatedAt
	}
	${EmbeddedPictureFragmentDoc}
`
export const SchoolFragmentDoc = gql`
	fragment School on School {
		_id
		name
		schoolCode
		affiliationCode
		email
		phone
		username
		location {
			city
			state
			pin
			address
		}
		picture {
			...EmbeddedPicture
		}
		cover {
			...EmbeddedPicture
		}
		createdAt
		updatedAt
	}
	${EmbeddedPictureFragmentDoc}
`
export const ExtendedAuthorFragmentDoc = gql`
	fragment ExtendedAuthor on ExtendedAuthor {
		authorType
		studentId
		student {
			...Student
		}
		staffId
		staff {
			...Staff
		}
		schoolId
		school {
			...School
		}
	}
	${StudentFragmentDoc}
	${StaffFragmentDoc}
	${SchoolFragmentDoc}
`
export const EmbeddedVideoFragmentDoc = gql`
	fragment EmbeddedVideo on EmbeddedVideo {
		key
		url
		thumbnail
		variants {
			small
			large
		}
		createdAt
		updatedAt
	}
`
export const EmbeddedPdfFragmentDoc = gql`
	fragment EmbeddedPDF on EmbeddedPDF {
		key
		url
		createdAt
		updatedAt
	}
`
export const MediaAttachmentsFragmentDoc = gql`
	fragment MediaAttachments on MediaAttachments {
		pictures {
			...EmbeddedPicture
		}
		videos {
			...EmbeddedVideo
		}
		pdfs {
			...EmbeddedPDF
		}
	}
	${EmbeddedPictureFragmentDoc}
	${EmbeddedVideoFragmentDoc}
	${EmbeddedPdfFragmentDoc}
`
export const ExtendedShowcaseFragmentDoc = gql`
	fragment ExtendedShowcase on ExtendedShowcase {
		_id
		author {
			...ExtendedAuthor
		}
		title
		description
		attachments {
			...MediaAttachments
		}
		authors {
			_id
			name
			picture {
				...EmbeddedPicture
			}
		}
		createdAt
		updatedAt
	}
	${ExtendedAuthorFragmentDoc}
	${MediaAttachmentsFragmentDoc}
	${EmbeddedPictureFragmentDoc}
`
export const ShowcaseFragmentDoc = gql`
	fragment Showcase on Showcase {
		_id
		title
		description
		attachments {
			...MediaAttachments
		}
		authors {
			_id
			name
			picture {
				...EmbeddedPicture
			}
		}
		createdAt
		updatedAt
	}
	${MediaAttachmentsFragmentDoc}
	${EmbeddedPictureFragmentDoc}
`
export const AllShowcasesDocument = gql`
	query AllShowcases($pagination: PaginatedInput!) {
		allShowcases(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			totalPages
			total
			currentPage
			showcases {
				...ExtendedShowcase
			}
		}
	}
	${ExtendedShowcaseFragmentDoc}
`

export function useAllShowcasesQuery(options: Omit<Urql.UseQueryArgs<AllShowcasesQueryVariables>, "query">) {
	return Urql.useQuery<AllShowcasesQuery>({ query: AllShowcasesDocument, ...options })
}
export const SearchShowcaseDocument = gql`
	query SearchShowcase($keyword: String!, $pagination: PaginatedInput!) {
		searchShowcase(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			totalPages
			total
			currentPage
			showcases {
				...ExtendedShowcase
			}
		}
	}
	${ExtendedShowcaseFragmentDoc}
`

export function useSearchShowcaseQuery(options: Omit<Urql.UseQueryArgs<SearchShowcaseQueryVariables>, "query">) {
	return Urql.useQuery<SearchShowcaseQuery>({ query: SearchShowcaseDocument, ...options })
}
export const ShowcaseByIdDocument = gql`
	query ShowcaseById($showcaseId: String!) {
		showcaseById(showcaseId: $showcaseId) {
			...ExtendedShowcase
		}
	}
	${ExtendedShowcaseFragmentDoc}
`

export function useShowcaseByIdQuery(options: Omit<Urql.UseQueryArgs<ShowcaseByIdQueryVariables>, "query">) {
	return Urql.useQuery<ShowcaseByIdQuery>({ query: ShowcaseByIdDocument, ...options })
}
