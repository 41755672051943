import type { SystemStyleObject } from "@chakra-ui/theme-tools"

const baseStyle: SystemStyleObject = {
	container: {
		rounded: "xl",
	},

	image: { rounded: "xl" },
}

export const Avatar = {
	baseStyle,
}
