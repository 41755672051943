/* eslint-disable @typescript-eslint/indent */
import { AspectRatio, Center, Flex, Grid, Heading, Icon, Img, LinkBox, LinkOverlay, Text, VStack } from "@chakra-ui/react"
import { faFilePdf } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedShowcaseFragment } from "../../graphql"
import { ShowcaseAuthor } from "./ShowcaseAuthor"

export type ShowcaseProps = {
	showcase: ExtendedShowcaseFragment
}

export const Showcase: React.FC<ShowcaseProps> = ({ showcase }) => {
	return (
		<VStack w="full" maxW="lg" bg="purple.50" align="flex-start" shadow="sm" rounded="xl" py={2} px={4}>
			<VStack as={LinkBox} w="full" align="stretch">
				<LinkOverlay as={NavLink} to={`/showcases/${showcase._id}`} />

				<VStack w="full" align="stretch" spacing="4">
					<VStack w="full" align="stretch" spacing={0}>
						<Heading as="h1" fontSize="xl" color="text.500">
							{showcase.title}
						</Heading>
						<Text fontSize="xs" color="text.500">
							Created {format(new Date(showcase.createdAt), "dd/MM/yyyy")}
						</Text>
					</VStack>

					{showcase.authors?.length && (
						<VStack w="full" align="stretch">
							<Heading fontSize="sm">Author</Heading>

							<Flex flexWrap="wrap" alignItems="center">
								{showcase.authors?.map((author) => (
									<ShowcaseAuthor key={author._id} author={author} />
								))}
							</Flex>
						</VStack>
					)}

					{showcase.attachments?.pdfs?.length ? (
						<Grid templateColumns={getGridTemplateColumns(showcase.attachments.pdfs.length || 0)} gap={1}>
							{showcase.attachments.pdfs.slice(0, 4).map((pdf, index) => (
								<AspectRatio
									pos="relative"
									key={index}
									bg="primary.100"
									rounded="xl"
									ratio={1}
									cursor="pointer"
									onClick={() => window.open(pdf.url, "#")}
									zIndex={1}
								>
									<>
										<Center rounded="xl" bg="primary.100">
											<Icon color="primary.600" fontSize="5xl" as={(props: any) => <FontAwesomeIcon icon={faFilePdf} {...props} />} />
										</Center>

										{index === 3 && (showcase?.attachments?.pdfs?.length || 0) > 4 && (
											<Center rounded="xl" pos="absolute" inset="0" bg="blackAlpha.700">
												<Text color="white" fontSize={{ base: "4xl", md: "5xl" }}>
													+{(showcase?.attachments?.pdfs?.length || 0) - 4}
												</Text>
											</Center>
										)}
									</>
								</AspectRatio>
							))}
						</Grid>
					) : showcase.attachments?.videos?.length ? (
						<Grid templateColumns={getGridTemplateColumns(showcase.attachments.videos.length)} gap={1}>
							{showcase.attachments.videos.slice(0, 4).map((video, index) => (
								<AspectRatio pos="relative" key={index} bg="primary.100" rounded="xl" ratio={1} zIndex={1}>
									<>
										<video style={{ borderRadius: "12px" }} width="100%" src={video.url} controls />
										{index === 3 && (showcase?.attachments?.videos?.length || 0) > 4 && (
											<Center rounded="xl" pos="absolute" inset="0" bg="blackAlpha.700">
												<Text color="white" fontSize={{ base: "4xl", md: "5xl" }}>
													+{(showcase?.attachments?.videos?.length || 0) - 4}
												</Text>
											</Center>
										)}
									</>
								</AspectRatio>
							))}
						</Grid>
					) : showcase.attachments?.pictures?.length ? (
						<Grid templateColumns={getGridTemplateColumns(showcase.attachments.pictures.length)} gap={1}>
							{showcase.attachments.pictures.slice(0, 4).map((picture, index) => (
								<AspectRatio key={index} pos="relative" bg="primary.100" rounded="xl" ratio={1} zIndex={1}>
									<>
										<Img rounded="xl" src={picture.url} />
										{index === 3 && (showcase?.attachments?.pictures?.length || 0) > 4 && (
											<Center rounded="xl" pos="absolute" inset="0" bg="blackAlpha.700">
												<Text color="white" fontSize={{ base: "4xl", md: "5xl" }}>
													+{(showcase?.attachments?.pictures?.length || 0) - 4}
												</Text>
											</Center>
										)}
									</>
								</AspectRatio>
							))}
						</Grid>
					) : (
						<></>
					)}

					<Text fontSize="sm" color="text.400" whiteSpace="pre-line">
						{showcase.description.substring(0, 240)}
						{showcase.description.length > 240 ? "..." : ""}
					</Text>
				</VStack>
			</VStack>
		</VStack>
	)
}

const getGridTemplateColumns = (length: number) => {
	if (length < 2) {
		return "1fr"
	}

	return "repeat(2, 1fr)"
}
