import React from "react"
import { Route, Routes } from "react-router-dom"
import { Showcases } from "../pages"
import { LandingPage } from "../pages/Landing"
import { Showcase } from "../pages/Showcase/Showcase"

export const RootRouter: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<LandingPage />} />
			<Route path="/showcases/:showcaseId" element={<Showcase />} />
			<Route path="/showcases" element={<Showcases />} />
		</Routes>
	)
}
