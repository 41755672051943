import { AspectRatio, Box, Button, chakra, Container, Heading, Icon, Img, SlideFade, Stack, Text, useOutsideClick, VStack } from "@chakra-ui/react"
import hexToRgba from "hex-to-rgba"
import React, { RefObject, useRef, useState } from "react"
import { ArrowRight } from "react-feather"
import { NavLink } from "react-router-dom"
import { Header } from "../components/common"
import { FeatureIcon } from "../components/common/FeatureIcon"
import { GRADIENT } from "../constants"

import { AlumniNetworkIcon, BusinessVisitsIcon, CommunityFeedIcon, ContestsIcon, InternshipsIcon, WorkshopsIcon } from "../icons"

type Features = "alumni-network" | "community-feed" | "workshops" | "internships" | "business-visits" | "contests"

const features = {
	"alumni-network": {
		title: "Alumni Network",
		content:
			"School doesn’t end after you graduate.\nNo matter where you are in life, you always wonder what your schoolmates are up to. With Learntank’s Alumni network, you can connect with all your childhood classmates. If you were in one school or five, you would be a part of all the circles. You can connect and communicate with your fellow alumnus by posting your updates. You can also contribute to the development of your school by donating right from Learntank.",
		color: "#FBC16C",
		icon: AlumniNetworkIcon,
	},
	"community-feed": {
		title: "Community Feed",
		content:
			"You can post your achievements, share any updates on the lessons and curriculum, and also if there are any events hosted in the school. You can congratulate a winning team and also connect with your fellow schoolmates. You can share educational video YouTube links as well as post videos and photos about your school life on this feed.",
		color: "#9FDDFF",
		icon: CommunityFeedIcon,
	},
	workshops: {
		title: "Workshops",
		content:
			"Hone your skills with with the myriad opportunities provided by Learntank. The workshops range across several categories of skills that would help with your personal and professional development. Upskilling leads to up ranking.",
		color: "#8DFFBB",
		icon: WorkshopsIcon,
	},
	internships: {
		title: "Internships",
		content:
			"The right internship at the right time can change your life. As students get access to an internship early on in life, they get a head start in their careers. Industry experience is crucial for a successful future and with the several internships provided by Learntank, your dream is just an application away.",
		color: "#FF8F8F",
		icon: InternshipsIcon,
	},
	"business-visits": {
		title: "Business Visits",
		content:
			"Early industry exposure is crucial for one's understanding of how the real world works. With Learntank's business visits, students get an opportunity to visit several companies big and small alike. With that knowledge, a child would be able to make the right career decisions as well be aware of what knowledge and opportunities are around them.",
		color: "#FFAF83",
		icon: BusinessVisitsIcon,
	},
	contests: {
		title: "Contests",
		content:
			"On your marks. Get set. Go.\nThe contests on Learntank cater to several segments of the industry.  We believe a fish shouldn’t be judged on its ability to climb a tree and a monkey should not be judged on its ability to swim. So if you are interested in drama or tech, abacus, or aeronautical science, LT has a competition for every child's interest area.",
		color: "#FFF387",
		icon: ContestsIcon,
	},
}

export const LandingPage: React.FC = () => {
	const [selectedFeature, setSelectedFeature] = useState<Features | null>()

	const ref = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>

	useOutsideClick({
		ref,
		handler: () => setSelectedFeature(null),
	})

	return (
		<VStack w="full" overflow="auto" bg={GRADIENT} spacing={{ base: 4, md: 16 }}>
			<Header />
			<Container flex="1" h="full" w="full" maxW="container.xl">
				<VStack w="full" align="stretch" spacing={24} pb="12">
					<Stack direction={{ base: "column-reverse", md: "row" }} align="center" justify="center" spacing={{ base: 6, md: 16 }}>
						{selectedFeature ? (
							<SlideFade ref={ref as any} style={{ flex: 1, width: "100%" }} in>
								<AspectRatio w="full" maxW="85vw" mx="auto" ratio={1}>
									<VStack
										borderRadius="60% 40% 30% 70% / 60% 30% 70% 40%"
										bg={`linear-gradient(112.37deg, ${hexToRgba(features[selectedFeature].color, 0.2)} 9.47%, ${
											features[selectedFeature].color
										} 78.42%)`}
										px="8"
										justify="space-between"
										spacing={6}
									>
										<Icon as={features[selectedFeature].icon} fontSize={{ base: "6xl", md: "9xl" }} color="text.500" />
										<VStack>
											<Heading fontSize="lg" color="text.500">
												{features[selectedFeature].title}
											</Heading>
											<Text fontSize={{ base: "xs", md: "sm" }} textAlign="center">
												{features[selectedFeature].content}
											</Text>
											<Button colorScheme="primary" size="sm" variant="link" rightIcon={<Icon as={ArrowRight} />}>
												Learn More
											</Button>
										</VStack>
									</VStack>
								</AspectRatio>
							</SlideFade>
						) : (
							<VStack as={SlideFade} in flex="1" h="full" align="flex-start" spacing={{ base: 6, md: 16 }}>
								<VStack flex="1" h="full" align="flex-start">
									<Heading maxW="sm" fontSize={{ base: "4xl", lg: "6xl" }} fontWeight="900" lineHeight="100%" color="primary.500">
										<chakra.span fontSize={{ base: "3xl", lg: "4xl" }}>Beyond the </chakra.span>
										classroom
									</Heading>
									<Heading fontSize={{ base: "sm", lg: "lg" }} fontWeight="normal" color="#777777">
										A mindfully curated learning experience
									</Heading>
								</VStack>
								<Button as={NavLink} to="/showcases" size="lg" colorScheme="purple" rightIcon={<Icon as={ArrowRight} />}>
									See showcases
								</Button>
							</VStack>
						)}
						<Box flex="1" pos="relative">
							<Img w="full" maxW="xl" src="/_pictures/hero_section_girl.png" />
							<FeatureIcon
								ref={ref as any}
								w="20%"
								bg={hexToRgba(features["alumni-network"].color, 0.8)}
								top="0"
								left="15%"
								transform="rotate(11.5deg)"
								title="Alumni Network"
								onClick={() => setSelectedFeature("alumni-network")}
							>
								<Icon as={features["alumni-network"].icon} fontSize={{ base: "2xl", md: "5xl" }} color="text.500" />
							</FeatureIcon>
							<FeatureIcon
								ref={ref as any}
								w="18%"
								bg={hexToRgba(features["community-feed"].color, 0.8)}
								top="20%"
								left="70%"
								transform="rotate(6deg)"
								title="Community Feed"
								onClick={() => setSelectedFeature("community-feed")}
							>
								<Icon as={features["community-feed"].icon} fontSize={{ base: "2xl", md: "5xl" }} color="text.500" />
							</FeatureIcon>
							<FeatureIcon
								ref={ref as any}
								w="15%"
								bg={hexToRgba(features["workshops"].color, 0.8)}
								top="35%"
								left="20%"
								transform="rotate(23.5deg)"
								title="Workshops"
								onClick={() => setSelectedFeature("workshops")}
							>
								<Icon as={features["workshops"].icon} fontSize={{ base: "2xl", md: "5xl" }} color="text.500" />
							</FeatureIcon>
							<FeatureIcon
								ref={ref as any}
								w="18%"
								bg={hexToRgba(features["internships"].color, 0.8)}
								top="60%"
								left="5%"
								transform="rotate(38deg)"
								title="Internships"
								onClick={() => setSelectedFeature("internships")}
							>
								<Icon as={features["internships"].icon} fontSize={{ base: "2xl", md: "5xl" }} color="text.500" />
							</FeatureIcon>
							<FeatureIcon
								ref={ref as any}
								w="19%"
								bg={hexToRgba(features["contests"].color, 0.8)}
								top="60%"
								left="70%"
								transform="rotate(-20deg)"
								title="Contests"
								onClick={() => setSelectedFeature("contests")}
							>
								<Icon as={features["contests"].icon} fontSize={{ base: "2xl", md: "5xl" }} color="text.500" />
							</FeatureIcon>
							<FeatureIcon
								ref={ref as any}
								w="18%"
								bg={hexToRgba(features["business-visits"].color, 0.8)}
								top="85%"
								left="45%"
								transform="rotate(27deg)"
								title="Business Visits"
								onClick={() => setSelectedFeature("business-visits")}
							>
								<Icon as={features["business-visits"].icon} fontSize={{ base: "2xl", md: "5xl" }} color="text.500" />
							</FeatureIcon>
						</Box>
					</Stack>
				</VStack>
			</Container>
		</VStack>
	)
}
