import { Avatar, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { ShowcaseAuthor as Author } from "../../graphql"

export type ShowcaseAuthorProps = {
	author: Author
}

export const ShowcaseAuthor: React.FC<ShowcaseAuthorProps> = ({ author }) => {
	return (
		<VStack position="relative" key={author._id} m="2" spacing={0}>
			<Avatar size="lg" src={author.picture.url} name={author.name} />
			<Text fontSize="sm" fontWeight="semibold">
				{author.name}
			</Text>
		</VStack>
	)
}
