import { AspectRatio, Img, Link } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"

export const SchoolLogo: React.FC = () => {
	return (
		<Link as={NavLink} to="/">
			<AspectRatio w={{ base: "60px", md: "100px" }} ratio={1}>
				<Img src="/prometheus-logo.png" w="full" style={{ objectFit: "contain" }} />
			</AspectRatio>
		</Link>
	)
}
