import { extendTheme } from "@chakra-ui/react"
import { colors } from "./colors"
import { Avatar, Button } from "./components"
import { fonts } from "./fonts"

export const theme = extendTheme({
	components: {
		Button,
		Avatar,
	},
	colors,
	fonts,
})
