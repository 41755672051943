import { Button, Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { AllShowcasesQueryVariables, useAllShowcasesQuery } from "../../graphql"
import { Showcase } from "./Showcase"

export const AllShowcases: React.FC = () => {
	const [pagination, setPagination] = useState<AllShowcasesQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useAllShowcasesQuery({
		variables: { pagination },
	})

	console.log(data?.allShowcases.showcases)

	const nextPage = () => {
		setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
	}

	return (
		<VStack w="full" align="stretch">
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.allShowcases.showcases && data?.allShowcases.showcases.length > 0 ? (
				<VStack w="full" align="stretch">
					{data?.allShowcases?.showcases?.map((showcase) => (
						<Showcase key={showcase._id} showcase={showcase} />
					))}
					{data.allShowcases.hasNextPage && (
						<Center w="full" maxW="lg" py="8">
							<Button variant="link" size="sm" colorScheme="blackAlpha" onClick={nextPage}>
								Show more
							</Button>
						</Center>
					)}
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any showcases.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
