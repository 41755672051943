import { AspectRatio, Img, Link } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"

export const Logo: React.FC = () => {
	return (
		<Link as={NavLink} to="/">
			<AspectRatio w={{ base: "80px", md: "120px" }} ratio={22 / 9}>
				<Img src="/logo-192x192.png" w="full" />
			</AspectRatio>
		</Link>
	)
}
