import { Container, HStack } from "@chakra-ui/react"
import React from "react"
import { Logo } from "./Logo"
import { SchoolLogo } from "./SchoolLogo"

export const Header: React.FC = () => {
	return (
		<Container maxW="container.xl">
			<HStack justify="space-between" py="3">
				<Logo />
				<SchoolLogo />
			</HStack>
		</Container>
	)
}
