import Icon, { IconProps } from "@chakra-ui/icon"
import React from "react"

export const BusinessVisitsIcon: React.FC<IconProps> = (props) => {
	return (
		<Icon viewBox="0 0 100 100" {...props}>
			<path
				d="M50.2684 29.3919L50.2684 12.7253L8.60168 12.7253L8.60168 87.7253L91.935 87.7253L91.935 29.3919L50.2684 29.3919ZM25.2684 79.3919L16.935 79.3919L16.935 71.0586H25.2684L25.2684 79.3919ZM25.2684 62.7253H16.935L16.935 54.3919H25.2684L25.2684 62.7253ZM25.2684 46.0586H16.935L16.935 37.7253L25.2684 37.7253L25.2684 46.0586ZM25.2684 29.3919L16.935 29.3919V21.0586H25.2684L25.2684 29.3919ZM41.935 79.3919H33.6017L33.6017 71.0586L41.935 71.0586V79.3919ZM41.935 62.7253L33.6017 62.7253V54.3919L41.935 54.3919L41.935 62.7253ZM41.935 46.0586L33.6017 46.0586V37.7253H41.935L41.935 46.0586ZM41.935 29.3919L33.6017 29.3919L33.6017 21.0586L41.935 21.0586V29.3919ZM83.6017 79.3919L50.2684 79.3919V71.0586H58.6017L58.6017 62.7253L50.2684 62.7253L50.2684 54.3919L58.6017 54.3919V46.0586L50.2684 46.0586L50.2684 37.7253L83.6017 37.7253L83.6017 79.3919ZM75.2684 46.0586H66.935V54.3919H75.2684L75.2684 46.0586ZM75.2684 62.7253H66.935V71.0586H75.2684V62.7253Z"
				fill="currentColor"
			/>
		</Icon>
	)
}
