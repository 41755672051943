export const colors = {
	primary: {
		"50": "#ffffff",
		"100": "#e5e1f9",
		"200": "#d2ccf5",
		"300": "#bfb6f1",
		"400": "#aca1ed",
		"500": "#8676E5",
		"600": "#604bdd",
		"700": "#4d36d9",
		"800": "#3f27ce",
		"900": "#321fa3",
	},
	text: {
		"50": "#aaaaaa",
		"100": "#919191",
		"200": "#848484",
		"300": "#777777",
		"400": "#6b6b6b",
		"500": "#515151",
		"600": "#373737",
		"700": "#2b2b2b",
		"800": "#1e1e1e",
		"900": "#040404",
	},
	white: {
		"50": "#ffffff",
		"100": "#ffffff",
		"200": "#ffffff",
		"300": "#ffffff",
		"400": "#ffffff",
		"500": "#F7F6F4",
		"600": "#e2ded6",
		"700": "#d7d2c8",
		"800": "#ccc6b9",
		"900": "#b7ae9b",
	},
}
