/* eslint-disable @typescript-eslint/ban-ts-comment */
import { cacheExchange as cE } from "@urql/exchange-graphcache"
import schema from "../generated/schema.json"
import { basicPagination } from "../pagination"

export const cacheExchange = cE({
	schema: schema as any,
	keys: {
		MediaAttachments: () => null,
		EmbeddedPicture: () => null,
		EmbeddedVideo: () => null,
		EmbeddedPDF: () => null,
	},
	resolvers: {
		Query: {
			allShowcases: basicPagination("showcases", "PaginatedShowcasesResponse"),
		},
	},
})
