import { Container, useBreakpointValue, VStack } from "@chakra-ui/react"
import React from "react"
import { AllShowcases, Header } from "../../components"

export const Showcases: React.FC = () => {
	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<VStack w="full" overflowY="auto" px={isMobile ? 2 : 4} pb={isMobile ? 16 : 4} spacing={0}>
				<Header />
				<VStack w="full" maxW="lg" spacing={isMobile ? 4 : 6}>
					<AllShowcases />
				</VStack>
			</VStack>
		</Container>
	)
}
