import { AspectRatio, Center, Container, Flex, Grid, Heading, Icon, Img, Spinner, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import { faFilePdf } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import { useParams } from "react-router-dom"
import { Header, ShowcaseAuthor } from "../../components"
import { useShowcaseByIdQuery } from "../../graphql"

export const Showcase: React.FC = () => {
	const { showcaseId = "" } = useParams<{ showcaseId: string }>()

	const [{ data, fetching, error }] = useShowcaseByIdQuery({
		variables: { showcaseId },
	})

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<VStack overflowY="auto" px={isMobile ? 2 : 4} pb={isMobile ? 16 : 4} spacing={0}>
				<Header />
				<VStack w="full" maxW="lg" spacing={isMobile ? 4 : 6}>
					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.showcaseById ? (
						<VStack w="full" align="stretch" spacing="4">
							<VStack w="full" align="stretch" spacing={0}>
								<Heading as="h1" fontSize="xl" color="text.500">
									{data.showcaseById.title}
								</Heading>
								<Text fontSize="xs" color="text.500">
									Created {format(new Date(data.showcaseById.createdAt), "dd/MM/yyyy")}
								</Text>
							</VStack>

							{data.showcaseById.authors?.length && (
								<VStack w="full" align="stretch">
									<Heading fontSize="sm">Author</Heading>

									<Flex flexWrap="wrap" alignItems="center">
										{data.showcaseById.authors?.map((author) => (
											<ShowcaseAuthor key={author._id} author={author} />
										))}
									</Flex>
								</VStack>
							)}

							{data.showcaseById.attachments?.pdfs?.length ? (
								<Grid templateColumns={getGridTemplateColumns(data.showcaseById.attachments.pdfs.length || 0)} gap={1}>
									{data.showcaseById.attachments.pdfs.slice(0, 4).map((pdf, index) => (
										<AspectRatio
											pos="relative"
											key={index}
											bg="primary.100"
											rounded="xl"
											ratio={1}
											cursor="pointer"
											onClick={() => window.open(pdf.url, "#")}
											zIndex={1}
										>
											<>
												<Center rounded="xl" bg="primary.100">
													<Icon
														color="primary.600"
														fontSize="5xl"
														as={(props: any) => <FontAwesomeIcon icon={faFilePdf} {...props} />}
													/>
												</Center>

												{index === 3 && (data.showcaseById?.attachments?.pdfs?.length || 0) > 4 && (
													<Center rounded="xl" pos="absolute" inset="0" bg="blackAlpha.700">
														<Text color="white" fontSize={{ base: "4xl", md: "5xl" }}>
															+{(data.showcaseById?.attachments?.pdfs?.length || 0) - 4}
														</Text>
													</Center>
												)}
											</>
										</AspectRatio>
									))}
								</Grid>
							) : data.showcaseById.attachments?.videos?.length ? (
								<Grid templateColumns={getGridTemplateColumns(data.showcaseById.attachments.videos.length)} gap={1}>
									{data.showcaseById.attachments.videos.slice(0, 4).map((video, index) => (
										<AspectRatio pos="relative" key={index} bg="primary.100" rounded="xl" ratio={1} zIndex={1}>
											<>
												<video style={{ borderRadius: "12px" }} width="100%" src={video.url} controls />
												{index === 3 && (data.showcaseById?.attachments?.videos?.length || 0) > 4 && (
													<Center rounded="xl" pos="absolute" inset="0" bg="blackAlpha.700">
														<Text color="white" fontSize={{ base: "4xl", md: "5xl" }}>
															+{(data.showcaseById?.attachments?.videos?.length || 0) - 4}
														</Text>
													</Center>
												)}
											</>
										</AspectRatio>
									))}
								</Grid>
							) : data.showcaseById.attachments?.pictures?.length ? (
								<Grid templateColumns={getGridTemplateColumns(data.showcaseById.attachments.pictures.length)} gap={1}>
									{data.showcaseById.attachments.pictures.slice(0, 4).map((picture, index) => (
										<AspectRatio key={index} pos="relative" bg="primary.100" rounded="xl" ratio={1} zIndex={1}>
											<>
												<Img rounded="xl" src={picture.url} />
												{index === 3 && (data.showcaseById?.attachments?.pictures?.length || 0) > 4 && (
													<Center rounded="xl" pos="absolute" inset="0" bg="blackAlpha.700">
														<Text color="white" fontSize={{ base: "4xl", md: "5xl" }}>
															+{(data.showcaseById?.attachments?.pictures?.length || 0) - 4}
														</Text>
													</Center>
												)}
											</>
										</AspectRatio>
									))}
								</Grid>
							) : (
								<></>
							)}

							<Text fontSize="sm" color="text.400" whiteSpace="pre-line">
								{data.showcaseById.description}
							</Text>
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the showcase.
							</Text>
						</Center>
					)}
				</VStack>
			</VStack>
		</Container>
	)
}

const getGridTemplateColumns = (length: number) => {
	if (length < 2) {
		return "1fr"
	}

	return "repeat(2, 1fr)"
}
