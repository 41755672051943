import React from "react"
import { RootRouter } from "./routes"

export const App: React.FC = () => {
	return (
		<div id="app">
			<RootRouter />
		</div>
	)
}
