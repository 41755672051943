import { AspectRatio, Box, BoxProps, Tooltip } from "@chakra-ui/react"
import React, { Ref } from "react"

export type FeatureIconProps = {
	title: string
	ref?: Ref<HTMLDivElement>
} & BoxProps

export const FeatureIcon: React.FC<FeatureIconProps> =
	// eslint-disable-next-line react/display-name
	React.forwardRef<HTMLDivElement, FeatureIconProps>(({ title, bg, children, ...props }, ref) => {
		return (
			<AspectRatio
				pos="absolute"
				top={props.top}
				bottom={props.bottom}
				left={props.left}
				right={props.right}
				maxW="full"
				w={props.w}
				ratio={1}
				transform={props.transform}
				{...props}
				ref={ref}
			>
				<Tooltip label={title} bg={bg} shadow="none" color="text.500">
					<Box
						rounded="2xl"
						backdropFilter="blur(6px)"
						cursor="pointer"
						_hover={{ transform: "scale(1.2)" }}
						transitionProperty="all"
						transitionDuration="100ms"
						transitionTimingFunction="ease-in-out"
						bg={bg}
					>
						{children}
					</Box>
				</Tooltip>
			</AspectRatio>
		)
	})
